import React from 'react';
import styled from 'styled-components';
import Typography from "@material-ui/core/Typography";

const WarningText = styled.div`
  color: red;
`;

const ErrorMessage = ({ message }) => (
  <If condition={message}>
    <WarningText>
      <Typography variant="caption">*{message}</Typography>
    </WarningText>
  </If>
);

export default ErrorMessage;
