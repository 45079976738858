import merge from "lodash/merge";
import { handleActions } from "redux-actions";

import * as actions from '../actions/crm';
import { INITIAL_FILTERS_CONTACTS, INITIAL_FILTERS_COMPANYS, INITIAL_FILTERS_PIPELINES } from '../../utils/constants';

export default handleActions(
	{
		[actions.createNotification]: {
			SUCCESS: (state, action) => {
				return merge({}, state, { notifications: [action.payload].concat(state.notifications) })
			}
		},
		[actions.updateFilterContacts]: {
			SUCCESS: (state, action) => Object.assign({}, state, { filterContacts: action.payload })
		},
		[actions.resetAllFilterContacts]: {
			SUCCESS: (state, action) => Object.assign({}, state, { filterContacts: INITIAL_FILTERS_CONTACTS })
		},
		[actions.resetOneFilterContacts]: {
			SUCCESS: (state, action) => {
				const filterContacts = {
					...state.filterContacts,
					[action.payload]: INITIAL_FILTERS_CONTACTS[action.payload]
				}
				return Object.assign({}, state, { filterContacts })
			}
		},
		[actions.updateFilterCompanys]: {
			SUCCESS: (state, action) => Object.assign({}, state, { filterCompanys: action.payload })
		},
		[actions.resetAllFilterCompanys]: {
			SUCCESS: (state, action) => Object.assign({}, state, { filterCompanys: INITIAL_FILTERS_COMPANYS })
		},
		[actions.resetOneFilterCompanys]: {
			SUCCESS: (state, action) => {
				const filterCompanys = {
					...state.filterCompanys,
					[action.payload]: INITIAL_FILTERS_COMPANYS[action.payload]
				}
				return Object.assign({}, state, { filterCompanys })
			}
		},
    [actions.updateFilterPipelines]: {
      SUCCESS: (state, action) => Object.assign({}, state, {filterPipelines: action.payload})
    },
    [actions.resetOneFilterPipelines]: {
      SUCCESS: (state, action) => {
        const filterPipelines = {
          ...state.filterPipelines,
          [action.payload]: INITIAL_FILTERS_PIPELINES[action.payload]
        }
        return Object.assign({}, state, { filterPipelines })
      }
    },
    [actions.resetAllFilterPipelines]: {
      SUCCESS: (state, action) => Object.assign({}, state, { filterPipelines: INITIAL_FILTERS_PIPELINES })
    },
		[actions.updateEmailDraft]: {
			SUCCESS: (state, action) => {
				const emailDrafts = {
					...state.emailDrafts,
					...action.payload
				};
				return Object.assign({}, state, { emailDrafts })
			}
		},
		[actions.deleteEmailDraft]: {
			SUCCESS: (state, action) => {
				const emailDrafts = state.emailDrafts;
				delete emailDrafts[action.payload.id];
				return Object.assign({}, state, { emailDrafts });
			}
		},
		[actions.getEmailSignature]: {
			SUCCESS: (state, action) => {
				return Object.assign({}, state, { emailSignature: action.payload });
			}
		},
		[actions.updateEmailSignature]: {
			SUCCESS: (state, action) => {
				return Object.assign({}, state, { emailSignature: action.payload });
			}
		},
	},
	// Initial state
	{
		notifications: [],
		filterContacts: INITIAL_FILTERS_CONTACTS,
		filterCompanys: INITIAL_FILTERS_COMPANYS,
    filterPipelines: INITIAL_FILTERS_PIPELINES,
		emailDrafts: {},
		emailSignature: ""
	}
);