import React, { useState, useEffect, useRef } from "react";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Divider from "@material-ui/core/Divider";
import styled from 'styled-components';

const StyledBox = styled(Box)`
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #000;
  }
`;

const InputField = ({
  label,
  helperText,
  multiline,
  rows,
  input,
  meta,
  autoFocus,
  placeholder,
  showCount,
  isModal,
  isPrevValue,
  setPrevValue,
  ...props
}) => {
  const [isFocus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const hasError = meta ? meta.error && meta.touched : null;

  const prevInput = useRef();

  useEffect(() => {
    if (isPrevValue) {
      prevInput.current = input.value;
      setPrevValue(prevInput.current);
    }
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl
      {...props}
      error={hasError}
      style={label !== "Image Title" ? { margin: "0 0 24px 0" } : null}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={input ? input.name : null}
          focused={isFocus}
          style={{
            display: 'flex',
            width: '133%',
            justifyContent: 'space-between'
          }}
        >
          {label}
          {label !== "Image Title" && showCount ? (
              <div style={{ float: "right" }}> {input?.value.length} </div>
          ) : null}
        </InputLabel>
      )}

      <StyledBox
        border={1}
        borderColor={
          isFocus ? "primary.main" : hasError ? "error.main" : "#c4c4c4"
        }
        paddingLeft={1}
        paddingRight={1}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        width={1}
        display="flex"
        flexDirection="column"
        marginTop={label ? "24px" : label === "Image Title" ? "5px" : 0}
        marginBottom={0}
        height={multiline ? "unset" : label === "Image Title" ? 0 : 50}
        borderRadius={6}
        justifyContent="center"
      >
        <InputBase
          {...input}
          id={input.name}
          aria-describedby={`${input.name}-helper-text`}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          autoFocus={autoFocus}
          type={showPassword ? "text" : "password"} // Toggle password visibility
          endAdornment={<>
          <Divider orientation="vertical" flexItem style={{marginRight: '2px' }}/>
          <IconButton
            onClick={handleTogglePasswordVisibility}
            tabIndex={-1}
            style={{ marginLeft: "auto" }} /* Position the button to the right */
          >
            {showPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
          </IconButton>
          </>
          }
        />
      </StyledBox>
      <FormHelperText id={`${input.name}-helper-text`} style={{alignSelf: 'flex-start'}}>
        <Choose>
          <When condition={meta.error && meta.touched}>{meta.error}</When>
          <When condition={helperText}>{helperText}</When>
        </Choose>
      </FormHelperText>
    </FormControl>
  );
};

export default InputField;
