import React, { useState } from 'react';
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Auth } from "aws-amplify";
import get from "lodash/get";

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";

import SnackProgress from "../../../../../../../shared/snack-progress";
import EmailSignature from "../../../../../../../shared/email-signature";
import AlertForm from "../../../../../../../shared/alert-form";
import FormDialog from '../../../../../../../shared/form-dialog';
import { GoogleCalendar } from './components';

import { useLists, useUser as useMailchimpUser, useSync } from '../../../../../../../../utils/crm/use-mailchimp';
import { MAILCHIMP_AUTH_URL, ACCOUNT_NAME } from '../../../../../../../../api/mailchimp';
import { GMAIL_AUTH_URL } from '../../../../../../../../api/gmail';
import { useGroups } from '../../../../../../../../utils/crm/use-group';
import { EMAIL_PATTERN } from '../../../../../../../../utils/constants';
import {
	useSaveDefaultEmail,
	useGetGmailAddressByUser,
	useGetEmailSignature,
	useUpdateSignature,
	useDeleteGmailConfig
} from '../../../../../../../../utils/crm/use-gmail';
import GoogleSvg from '../../../../../../../../static/google/btn_google_light_normal_ios.svg';

const GoogleLogo = styled(GoogleSvg)`
	margin-right: 16px;
	width: 40px;
	height: auto;
	background-color: #FFFFFF;
	border-radius: 4px;
`;

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		backgroundColor: theme.palette.background.default
	},
	accordion: {
		width: '100%',
		'& .MuiAccordionDetails-root': {
			flexDirection: 'column',
		}
	},
	statusText: {
		'& .MuiTypography-root': {
			paddingTop: '6px'
		}
	},
	formControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		minWidth: 200,
		'& .MuiInputBase-formControl': {
			height: '56px',
		},
	},
	formRoot: {
		width: 'inherit',
		marginTop: '16px'
	},
	formEmail: {
		'& .MuiButton-root': {
			marginTop: theme.spacing(1),
		},
	},
	inputEmail: {
		width: '80%',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
			fontWeight: 400,
		},
		'& .MuiInputLabel-outlined': {
			backgroundColor: 'white',
			paddingRight: '8px'
		},
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	boxRoot: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginBottom: theme.spacing(2)
	},
	authorizeBtn: {
		width: 'fit-content',
		marginTop: theme.spacing(2)
	},
	authorizeGoogleBtn: {
		width: 'fit-content',
		marginTop: theme.spacing(2),
		backgroundColor: '#4285F4',
		padding: '4px 16px 4px 4px',
	}
}));

const styles = {
	inputLabel: {
		font: 'inherit',
		color: '#152939',
		height: '1.1876em',
		fontSize: '14px',
		boxSizing: 'content-box',
		letterSpacing: 'inherit',
	}
};

const checkDuplicates = values => {
	const errors = {};
	const freq = {};
	values.map(el => {
		if (el.sync == true) {
			const groupId = get('crm_group.id', el);
			if (freq[groupId]) {
				freq[groupId]++;
			} else {
				freq[groupId] = 1;
			}
		}
	});
	for (let key in freq) {
		if (freq[key] > 1) {
			errors.syncs = 'Same Connect Group selected in more than one Mailchimp Audience';
		}
	}
	return errors;
};

const SyncItem = ({ groups, name, index, fields }) => {
	const classes = useStyles();
	const item = fields.value[index];
	const [selected, setSelected] = useState(item.crm_group);
	const [checked, setChecked] = useState(item.sync);

	// console.log(fields)
	// console.log(index)
	// console.log(item)
	// console.log(selected)

	const handleSelect = (event, value) => {
		// console.log(value)
		setSelected(value);
		fields.update(index, { ...item, crm_group: value });
	};

	const handleSwitch = () => {
		fields.update(index, { ...item, sync: !checked ? true : false })
		setChecked(prev => !prev);
	};

	return (
		<Box className={classes.boxRoot}>
			<Field
				name={name}
				component={() => (
					<FormControl className={classes.formControl}>
						<TextField
							label="Mailchimp Audience"
							variant="outlined"
							value={item.list_name}
							id="select-mailchimp-audience"
						/>
					</FormControl>
				)}
			/>
			<Field
				name="crm_group"
				component={() => (
					<FormControl className={classes.formControl}>
						<Autocomplete
							id="select-crm-group-label"
							options={groups}
							value={selected || ""}
							onChange={handleSelect}
							getOptionLabel={(option) => option.name}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Connect Group"
									variant="outlined"
									InputLabelProps={{ style: styles.inputLabel }}
								/>
							)}
						/>
					</FormControl>
				)}
			/>
			<If condition={selected}>
				<Field
					component={() => (
						<FormControl component="fieldset">
							<FormControlLabel
								control={
									<Switch
										checked={checked}
										onChange={handleSwitch}
										color="primary"
										name="checkedB"
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								}
								label={`Sync ${checked ? 'On' : 'Off'}`}
							/>
						</FormControl>
					)}
					name="checkbox-sync"
				/>
			</If>
		</Box>
	)
};

const SyncForm = ({ groups, lists, onSubmit }) => {
	const classes = useStyles();
	// console.log(lists)
	const initialValues = {
		syncs: lists.map(list => ({
			list_id: list.id,
			list_name: list.name,
			crm_group: groups.find(group => group.mailchimp_list_id === list.id) || null,
			sync: groups.find(group => group.mailchimp_list_id === list.id) ? true : false
		}))
	};

	if (lists.length === 0) {
		return (
			<ListItem disableGutters>
				<ListItemText primary="Lists/audiences not found for the user's Mailchimp account" />
			</ListItem>
		)
	}

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitSucceeded, pristine, errors, ...props }) => {
				const errorGroups = errors.syncs && errors.syncs['FINAL_FORM/array-error'];
				return (
					<form onSubmit={handleSubmit} id="sync-mailchimp" className={classes.formRoot}>
						<FieldArray
							name="syncs"
							validate={checkDuplicates}
							render={({ fields }) => fields.map((name, index) => (
								<SyncItem
									key={index}
									index={index}
									groups={groups}
									name={name}
									fields={fields}
								/>
							))}
						/>
						<If condition={errors.syncs}>
							<ListItem disableGutters>
								<Typography color="error" variant="caption">{errors.syncs['FINAL_FORM/array-error'].syncs}</Typography>
							</ListItem>
						</If>
						<Button variant="contained" color="primary" type="submit" disabled={submitSucceeded || pristine || errorGroups.syncs}>Save settings and sync</Button>
					</form>
				)
			}}
		</Form>
	);
};

const TagForm = () => {
	const classes = useStyles();
	return(
	<>
	<Box className={classes.boxRoot}>
		<FormControl variant="outlined" className={classes.formControl} disabled>
			<InputLabel id="demo-simple-select-outlined-label">Newsletter</InputLabel>
			<Select
			labelId="demo-simple-select-outlined-label"
			id="demo-simple-select-outlined"
			value={10}
			//   onChange={handleChange}
			label="Newsletter   s"
			>
			<MenuItem value={10}>LA</MenuItem>
			</Select>
		</FormControl>
		<FormControl variant="outlined" className={classes.formControl} disabled>
			<InputLabel id="demo-simple-select-outlined-label">Connect Group</InputLabel>
			<Select
			labelId="demo-simple-select-outlined-label"
			id="demo-simple-select-outlined"
			value={10}
			//   onChange={handleChange}
			label="Connect Group   ..."
			>
			<MenuItem value={10}>Los Angeles</MenuItem>
			</Select>
		</FormControl>
	  </Box>
	  <Box className={classes.boxRoot}>
		<FormControl variant="outlined" className={classes.formControl} disabled>
			<InputLabel id="demo-simple-select-outlined-label">Newsletter</InputLabel>
			<Select
			labelId="demo-simple-select-outlined-label"
			id="demo-simple-select-outlined"
			value={10}
			//   onChange={handleChange}
			label="Newsletter   s"
			>
			<MenuItem value={10}>SF</MenuItem>
			</Select>
		</FormControl>
		<FormControl variant="outlined" className={classes.formControl} disabled>
			<InputLabel id="demo-simple-select-outlined-label">Connect Group</InputLabel>
			<Select
			labelId="demo-simple-select-outlined-label"
			id="demo-simple-select-outlined"
			value={10}
			//   onChange={handleChange}
			label="Connect Group   ..."
			>
			<MenuItem value={10}>San Francisco</MenuItem>
			</Select>
		</FormControl>
	  </Box>
	</>
	)
}

const DefaultEmailForm = ({ onSubmit, id, initialValues }) => {
	const classes = useStyles();
	const mustBeEmail = value => !value ? undefined : ((EMAIL_PATTERN.test(value) ? undefined : 'Email not valid'));
	return (
		<Form onSubmit={onSubmit} initialValues={initialValues} >
			{({ handleSubmit, modified, ...props }) => {
				// console.log(props)
				// console.log(modified)
				return (
					<form onSubmit={handleSubmit} id={id}>
						<Field
							name="default_email"
							variant="outlined"
							validate={mustBeEmail}
							allowNull
							parse={value => value == '' ? null : value}
							render={({ input, meta, ...rest }) => (
								<>
									<TextField
										{...input}
										{...rest}
										className={classes.inputEmail}
										id={input.name}
										label="Gmail address"
										placeholder="Insert address"
									/>
									<Button
										type="submit"
										color="primary"
										disableFocusRipple
										disabled={!modified.default_email}
										className={classes.authorizeGoogleBtn}
										variant="contained"
									>
										<GoogleLogo />
										<Typography style={{ fontWeight: 600 }}>Sign in with Google</Typography>
									</Button>
									<FormHelperText id={`${input.name}-helper-text`}>
										<If condition={meta.error && meta.touched}>
											<Typography variant="caption" color="error">{meta.error}</Typography>
										</If>
									</FormHelperText>
								</>
							)}
						/>
					</form>
				)
			}}
		</Form>
	);
};

const Settings = ({ open, onCancel }) => {
	const classes = useStyles();
	const cognito_user_email = Auth.user.attributes?.email;
	const mailchimpUser = useMailchimpUser(cognito_user_email);
	const [dialog, setDialog] = useState(null);
	const [expanded, setExpanded] = useState(false);
	const [progress, setProgress] = useState(null);
	const [trigger, setTrigger] = useState(false);
	const lists = useLists(cognito_user_email);
	const [{ groups }] = useGroups(trigger);
	const gmailAddress = useGetGmailAddressByUser(cognito_user_email, trigger);
	const signature = useGetEmailSignature(cognito_user_email);
	const deleteGmailConfig = useDeleteGmailConfig();
	const saveSignature = useUpdateSignature();
	const sync = useSync(cognito_user_email);
	const saveGmailConfig = useSaveDefaultEmail();
	const handleSubmit = async values => {
		setProgress({
			text: 'Saving settings. This may take a while.',
			severity: 'info'
		});
		try {
			const data = await sync(values);
			setProgress({
				text: 'Settings saved',
				severity: 'success'
			});
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while saving your settings',
				severity: 'error'
			});
		}
		setTimeout(() => {
			setProgress(null);
			setTrigger(prev => !prev);
			onCancel();
		}, 2000);
	};
	const handleAuthorizeGmail = async values => {
		const gmail_auth_href = `${GMAIL_AUTH_URL}&state=${paramState}&login_hint=${values.default_email}`;
		setProgress({
			text: 'Saving settings.',
			severity: 'info'
		});
		try {
			const data = await saveGmailConfig({ ...values, cognito_user_email });
			setProgress({
				text: 'Redirecting to Gmail Authorization page',
				severity: 'success'
			});
			setTimeout(() => {
				setProgress(null);
				setTrigger(prev => !prev);
				window.location = gmail_auth_href;
			}, 2000);
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while saving your settings',
				severity: 'error'
			});
			setTimeout(() => {
				setProgress(null);
				setTrigger(prev => !prev);
			}, 2000);
		}
	};
	const handleSaveSignature = async values => {
		// console.log(values)
		setProgress({
			text: 'Saving signature...',
			severity: 'info'
		});
		try {
			const data = await saveSignature(values);
			setProgress({
				text: 'Signature saved',
				severity: 'success'
			});
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while saving signature',
				severity: 'error'
			});
		}
		setTimeout(() => {
			setProgress(null);
			setTrigger(prev => !prev);
		}, 2000);
	};
	const handleDeauthorizeGmail = async () => {
		setDialog(null);
		setProgress({
			text: 'Removing Gmail settings',
			severity: 'info'
		});
		try {
			const data = await deleteGmailConfig({ cognito_user_email });
			setProgress({
				text: 'Gmail deauthorized!',
				severity: 'success'
			});
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while deauthorizing',
				severity: 'error'
			});
		}
		setTimeout(() => {
			location.reload();
			// setProgress(null);
			// setTrigger(prev => !prev);
		}, 2000);
	};
	const handleExpansion = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const paramState = JSON.stringify({ cognito_user_email, accountname: ACCOUNT_NAME });
	return (
		<>
			<Dialog onClose={onCancel} open={open} fullWidth onEntered={e => e.click(e)}>
				<DialogTitle id="simple-dialog-title">Settings</DialogTitle>
				<DialogContent dividers className={classes.dialogContent}>
					<div className={classes.accordion}>
						<Accordion expanded={expanded === 'panel1'} onChange={handleExpansion('panel1')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant="h6">Mailchimp Integration</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<If condition={!mailchimpUser}>
									<Button
										color="primary"
										disableFocusRipple
										className={classes.authorizeBtn}
										component="a"
										href={`${MAILCHIMP_AUTH_URL}&state=${paramState}`}
										variant="contained"
									>
										Authorize Mailchimp
									</Button>
								</If>
								<Typography style={{ paddingTop: '0px' }} gutterBottom variant="body2">
									Status: {mailchimpUser ? `authorized by ${mailchimpUser}` : 'user not authorized to change Mailchimp settings'}
								</Typography>
								<If condition={mailchimpUser}>
									<>
									<Typography variant="subtitle" style={{ paddingTop: '6px' }}>Audiences</Typography>
									<SyncForm lists={lists} groups={groups} onSubmit={handleSubmit} />
									<If condition={lists.some(e => e.name === "Panafold" || e.name === '33SIXTY NEWSLETTER')}>
										<Typography variant="subtitle" style={{ paddingTop: '20px' }}>Tags</Typography>
										{/* Hardcoding la and sf tags */}
										<TagForm />
									</If>
									</>
								</If>
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel2'} onChange={handleExpansion('panel2')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<Typography variant="h6">Gmail Integration</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Choose>
									<When condition={gmailAddress}>
										<Button
											color="primary"
											disableFocusRipple
											className={classes.authorizeBtn}
											variant="contained"
											onClick={() => setDialog("alert-deauthorize")}
										>
											Deauthorize {gmailAddress}
										</Button>
									</When>
									<Otherwise>
										<DefaultEmailForm
											onSubmit={handleAuthorizeGmail}
											id="default-email-address"
											initialValues={{ default_email: gmailAddress }}
										/>
									</Otherwise>
								</Choose>
								<If condition={gmailAddress}>
									<Divider className={classes.divider} />
									<EmailSignature
										onSubmit={handleSaveSignature}
										initialValues={{
											cognito_user_email,
											signature
										}}
									/>
								</If>
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel3'} onChange={handleExpansion('panel3')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel3a-content"
								id="panel3a-header"
							>
								<Typography variant="h6">Google Calendar Integration</Typography>
							</AccordionSummary>
							<GoogleCalendar setProgress={setProgress} />
						</Accordion>
					</div>
				</DialogContent>
				<If condition={progress}>
					<SnackProgress progress={progress} />
				</If>
			</Dialog>
			<FormDialog
				Form={AlertForm}
				id="deauthorize-gmail"
				title="Deauthorize Gmail"
				open={dialog === "alert-deauthorize"}
				onCancel={() => setDialog(null)}
				onSubmit={handleDeauthorizeGmail}
				dialogText={() => (
					<>
						<Typography>
							This action disables sending emails from the Connect App.
            </Typography>
						<Typography>
							Are you sure you want to deauthorize the Gmail API?
            </Typography>
					</>
				)}
				cancelLabel="Return"
				submitLabel="Yes, deauthorize"
			/>
		</>
	);
};

export default Settings;