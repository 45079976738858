import palette from './palette';

export default {
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    // fontSize: "2rem",
    // fontWeight: 600,
    // lineHeight: 1.25,
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1.375
  },
  h2: {
    // fontSize: "1.75rem",
    // fontWeight: 600,
    // lineHeight: 1.25,
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: 1.375
  },
  h3: {
    // fontSize: "1.5rem",
    // fontWeight: 600,
    // lineHeight: 1.25,
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.375
  },
  h4: {
    // fontSize: "1.125rem",
    // fontWeight: 500,
    // lineHeight: 1.25,
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: 1.375
  },
  h5: {
    // fontSize: "1.0625rem",
    // fontWeight: 500,
    // lineHeight: 1.25,
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: 1.375
  },
  h6: {
    // fontSize: "1rem",
    // fontWeight: 500,
    // lineHeight: 1.25,
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: 1.375
  },
  subtitle1: {
    // fontSize: '0.9285714285714286rem',
    // // font?amily: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    // fontWeight: 400,
    // lineHeight: 1.75
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75
  },
  subtitle2: {
    // color: palette.text.secondary,
    // fontWeight: 400,
    // fontSize: '14px',
    // letterSpacing: '-0.05px',
    // lineHeight: '21px'
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.57
  },
  body1: {
    // fontSize: 13,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body2: {
    // fontSize: 12,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.57
  },
  button: {
    textTransform: "none",
    fontWeight: 600
  },
  caption: {
    color: palette.text.secondary,
    // fontSize: '11px',
    // letterSpacing: '0.33px',
    // lineHeight: '13px'
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66
  },
  overline: {
    color: palette.text.secondary,
    // fontSize: '11px',
    // fontWeight: 500,
    // letterSpacing: '0.33px',
    // lineHeight: '13px',
    // textTransform: 'uppercase'
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase'
  }
};