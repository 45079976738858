import merge from "lodash/merge";
import { handleActions } from "redux-actions";

import * as actions from '../actions/user';

export default handleActions({
  [actions.getUserAttributes]: {
    SUCCESS: (state, action) => action.payload
  },
  [actions.updateUser]: {
    SUCCESS: (state, action) => Object.assign({}, state, { user: action.payload })
  },
},{});