import React from 'react';
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Status = styled.span`
    font-size: 24px;
    font-weight: 500;
    // color: #FFF;
    margin-top: 30px;
`;

const Loading = () => (
  <Box
    position="absolute" 
    zIndex="2200" 
    width="100vw" 
    display="flex" 
    margin="auto" 
    top="0"
    left="0"
    height="100vh" 
    justifyContent="center" 
    alignItems="center" 
    flexDirection="column" 
    bgcolor="#111827 !important"
  >
    <CircularProgress color="primary.contrastText" style={{color: '#fff'}}/>
    <Status>Connecting...</Status>
  </Box>
);

export default Loading;