import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  ActionsContainer,
  DetailsContainer,
} from './components';

const DrawerContainer = (props) => {
  const { children, open, onClose, onKill, variant, center, contentId, } = props;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        style:{
          minWidth: '500px',
          width: center? '90vw': '20vw',
          borderRadius: '14px',
          margin: '10px 10px 10px 0',
          height: 'calc(100% - 20px)',
          paddingX: '12px',
          marginRight: center? '5vw' : '10px'
        }
      }}
      SlideProps={{
        direction: 'left',
        appear: true
      }}
    >
      <Box display="flex" flexDirection="column" flex="1">
        <ActionsContainer {...props} />
        <Choose>
          <When condition={variant === 'details'}>
            <DetailsContainer {...props} onClose={onClose}/>
          </When>
          <Otherwise>
            <Typography>no variant passed.</Typography>
          </Otherwise>
        </Choose>
      </Box>
    </Drawer>
  );
};

DrawerContainer.defaultProps = {
  center: false
};

export default DrawerContainer;