import grey from "@material-ui/core/colors/grey";
import palette from './palette';

export default {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
    contained: {
        boxShadow: 'none',
    }
  },
  MuiButtonBase: {
    root: {
      // fontSize: '0.875rem',
      fontSize: '13px'
    }
  },
  MuiInputLabel: {
    root: {
      color: '#152939',
    },  
  },
  MuiTableCell :{
    root: {
      // fontSize: '0.875rem'
      fontSize: '13px'
    }
  },
  MuiTableRow :{
    root: {
      '&$selected': {
        backgroundColor: palette.background.default
      },
      '&$hover': {
        '&:hover': {
          backgroundColor: palette.background.default
        }
      }
    }
  },
  MuiInputBase: {
    input: {
      paddingHorizontal: '4px',
      color: '#152939',
      fontSize: '14px',
      '&::placeholder': {
        color: '#142939',
        fontSize: '14px',
        fontWeight: '400',
      }
    },
  },
  MuiListItemIcon: {
    root: {
      color: "inherit",
    }
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      // minWidth: "auto !important"
    }
  },
  MuiTabs: {
    root: {
      borderBottom: `1px solid ${grey[300]}`,
      flex: 1,
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: 16,
      fontWeight: 500
    }
  },
  MuiToggleButton: {
    root: {
        textTransform: 'none',
        color: 'rgba(50, 72, 86, 0.38)',
        '&$selected': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            '&:hover': {
                backgroundColor: palette.primary.dark,
            },
        },
    },
  },
  MuiChip: {
    root: {
      borderRadius: 3
    }
  },
  MuiListSubheader: {
    root: {
      fontSize: '0.875rem'
    }
  },
  MuiIconButton: {
    root: {
      borderRadius: 2
    }
  },
  MuiTableHead: {
    root: {
      backgroundColor: '#F3F4F6',
      '.MuiTableCell-root': {
        color: '#374151'
      },
      borderBottom: 'none',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: 0.5,
        textTransform: 'none'
      },
      '& .MuiTableCell-paddingCheckbox': {
        paddingTop: 4,
        paddingBottom: 4
      }
    }
  }
}