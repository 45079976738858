import { createPromise } from 'redux-promise-middleware';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { reducer } from '../redux';

export default (initialState) => {
  let store;
  const isClient = typeof window !== 'undefined';
  const promise = createPromise({ promiseTypeDelimiter: '/', promiseTypeSuffixes: ['PENDING', 'SUCCESS', 'ERROR'] });
  const middlewares = applyMiddleware(promise);

  if (isClient) {
    const { persistReducer } = require('redux-persist');
    const storage = require('redux-persist/lib/storage').default;
    const persistConfig = {
      key: 'root',
      storage
    };
    store = createStore(
      persistReducer(persistConfig, reducer),
      initialState,
      composeWithDevTools(middlewares)
    );
    store.__PERSISTOR = persistStore(store);
  } else {
    store = createStore(
      reducer,
      initialState,
      composeWithDevTools(middlewares)
    );
  }

  return store;
};
