import React from 'react';
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import InputField from '../../../../../../../../shared/input-field';

const AccountForm = ({ id, initialValues, onSubmit }) => {
  const required = value => (value ? undefined : 'Required');

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit}) => (
        <AccountForm.Root onSubmit={handleSubmit} id={id}>
          <Field
            name="name"
            component={InputField}
            label="Name"
            fullWidth
            validate={required}
          />
          <Field 
            name="email"
            component={InputField}
            label="Email"
            fullWidth
            disabled
            helperText="Contact administrator to change email"
          />
        </AccountForm.Root>
      )}
    </Form>
  )
};

AccountForm.Root = styled.form`
  width: 320px;
`

export default AccountForm;