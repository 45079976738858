import React from 'react';
import styled from 'styled-components';
import { useRouter } from "next/router";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import MuiButton from '@material-ui/core/Button';
import Link from "next/link";
import ClarenceBG from '../../../../static/images/Clarence.svg';
import { PrivacyPolicy, TermsOfService } from './components';
import { Changes } from '../../../sidebar-page/components/Topbar/components/Changelog/components';

import { VERSIONS } from '../../../../utils/changelog';

const CURRENT_VERSION = VERSIONS[0].version;

const Root = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #1D76D3;
`;

const Container = styled.div`
  align-content: center;
  text-align: center;
`;

const Logo = styled.img`
  // margin-top: 75px;
  width: 100%;
  max-width: 75px;
  margin-right: 25px;
  object-fit: contain;
  // margin: auto;
  max-height: 100px;
  @media screen and (max-width: 900px) {
    max-width: 60px;
  }
`;

const Button = styled(MuiButton)`
  color: #FFF !important;
  margin-right: 35px;
`;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '4rem',
    fontWeight: 600
  },
  subtitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: '10%'
  },
  container: {
    // border: '1px solid red',
    maxWidth: '500px',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  links: {
    maxWidth: '500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 75,
    textAlign: 'center',
    justifyContent: 'center'
  }
}))

const AuthPage = ({ children, authState, onStateChange, authData }) => {
  const router = useRouter();
  const classes = useStyles();
  const { query } = router;
  const [dialog, updateDialog] = React.useState(null);

  return (
    <If condition={authState !== "signUp" && authState !== "signedIn"}>
      <Root>
        <Grid container className={classes.container}>
          <Box display="flex" flexDirection="row">
            <Logo src={"/static/images/logo/panafold-logo-white-transparent.png"} />
            <Typography className={classes.title}>Connect</Typography>
          </Box>
          <Typography className={classes.subtitle}>Content and customer management all on a single platform</Typography>
          {
            React.Children.map(children, child =>
              React.cloneElement(child, { onStateChange, authState, authData }))
          }
        </Grid>  
        <Grid container className={classes.links}>
            <Link href={{ pathname: '/', query: { dialog: 'privacy-policy' }}}>
              <Button>Privacy Policy</Button>
            </Link>
            <Link href={{ pathname: '/', query: { dialog: 'terms-of-service' }}}>
              <Button>Terms of Service</Button>
            </Link>
            <Button onClick={() => onStateChange("signUp", {})} target="_blank">
              About Connect
            </Button>
            <Button component="a" href="https://www.panafold.co/about" target="_blank">
              Our Team
            </Button>
            <Button onClick={() => updateDialog('change-log')} disabled>
              v.{CURRENT_VERSION}
            </Button>
            <PrivacyPolicy open={query.dialog === 'privacy-policy'} onClose={() => router.push("/")} />
            <TermsOfService open={query.dialog === 'terms-of-service'} onClose={() => router.push("/")} />
            <Changes open={dialog === 'change-log'} onCancel={() => updateDialog(null)} />
          </Grid>  
      </Root>
    </If>
  )
}

export default AuthPage;