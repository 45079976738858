import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextTermsOfService from '../../../../../../utils/terms-of-service';

const TermsOfService = ({open, onClose}) => {
  return(
    <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        PaperProps={{style: {
          maxWidth: 800
        }}}
        onBackdropClick={onClose}
      >
        <DialogTitle style={{textAlign: 'center', fontSize: 18}}>Acceptable Use Policy</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <TextTermsOfService />
          </DialogContentText>
        </DialogContent>
      </Dialog>
  )
};

export default TermsOfService;