import orderBy from 'lodash/orderBy';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { CreatedActivityCard, UpdatedActivityCard } from '../../../../activity-components';
import { getObjectValue } from '../../../../../utils/formatting';
import { PIPELINE_PHASE } from '../../../../../utils/constants';

const ActivityCard = ({
  activity,
  target,
  isLoading
}) => {
  const sortedActivity = orderBy(activity, ["createdAt"], ["desc", "asc"]);

  return(
    <Paper elevation={0}>
      {/* <CardHeader
        title="Activity"
        titleTypographyProps={{variant: 'subtitle1'}}
      /> */}
      {/* <Divider /> */}
      <Box width="100%" paddingX={1} paddingBottom={1}>
        <Choose>
          <When condition={isLoading}>
            {[0,1,2].map(i => {
              return (
                <h1>SHOW LOADING...</h1>
            )})}
          </When>
          <Otherwise>
            {sortedActivity.map((item) => {
              item.description === "Phase" ? item["phase"] = getObjectValue(PIPELINE_PHASE, item.phase) : null
              // item.payload.target === "Sale Value" ? item.payload["note"] = formatCurrency(item.payload?.note) : null
              return (
                <Choose>
                  <When condition={item.type === "created"}>
                    <CreatedActivityCard content={item} type={"Pipeline"} />
                  </When>
                  <Otherwise>
                    <UpdatedActivityCard content={item} />
                  </Otherwise>
                </Choose>
              )
            })}
          </Otherwise>
        </Choose>
      </Box>
    </Paper>

  )
};

export default ActivityCard;