import React, {useState} from "react";
import { Field, Form } from "react-final-form";
import { Auth } from "aws-amplify";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import InputField from '../../../shared/input-field';
import { PasswordField } from "../components";
import ErrorMessage from '../error-message';
import SidebarContent from '../sidebar-content/SidebarContent';
import AuthPage from '../auth-page';

import { 
ContentWrapper,
InputForm,
FormCard,
SignInButtons,
HeaderLabel,
HeaderImage
} from "../../styles";
import { required } from "../../validators";
import SnackProgress from '../../../shared/snack-progress';

const Root = styled.div`
  display: flex;
`
const SignIn = ({ authState, onStateChange }) => {
  const [progress, setProgress] = useState(null);
  let error = null;

  const onSubmit = async ({ username, password }) => {
    // reset error message
    error = null;

    try {
      setProgress({
        text: 'Verifying...',
        severity: 'info'
      });
      const user = await Auth.signIn(username.trim(), password);
      
      if(user.challengeName === 'NEW_PASSWORD_REQUIRED'){
        setProgress({
          text: 'New Password Required!',
          severity: 'warning'
        });
        setTimeout(() => {
          onStateChange('RequireNewPassword', {user});
          setProgress(null);
        }, 2000);
      }else {
        setProgress({
          text: 'Success!',
          severity: 'success'
        });
        setTimeout(() => {
          onStateChange("signedIn");
          setProgress(null);
        }, 2000);
      }
    } catch (e) {
      // setProgress(null)
      error = e.message;
      setProgress({
        text: e.message,
        severity: 'error'
      });
      setTimeout(() => setProgress(null), 2000);
    }
  };

  return (
    <If condition={authState === "signIn"}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <ContentWrapper>
            <FormCard>
              <InputForm square onSubmit={handleSubmit}>
                {/* <HeaderImage src={"/static/images/33sixty_logo.png"}/> */}
                <HeaderLabel>Sign in to Panafold Connect</HeaderLabel>
                <Field
                  name="username"
                  component={InputField}
                  label="Username *"
                  margin="normal"
                  placeholder="Enter your username"
                  validate={required}
                  error={error}
                  // marginBottom={100}
                  marginbottom={100}
                />
                <Field
                  name="password"
                  component={PasswordField}
                  label="Password *"
                  margin="none"
                  type="password"
                  placeholder="Enter your password"
                  validate={required}
                  error={error}
                />
                <span>
                  Forgot your password?
                  <Button
                    variant="text"
                    size="small"
                    disableRipple
                    color="primary"
                    className="reset-button"
                    onClick={() => onStateChange("forgotPassword", {})}
                  >
                    Reset Password
                  </Button>
                </span>
                <ErrorMessage message={error} />
                <SignInButtons>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    Sign In
                  </Button>
                </SignInButtons>
              </InputForm>
            </FormCard>
          </ContentWrapper>
        )}
      </Form>
      <If condition={progress}>
        <SnackProgress progress={progress} />
      </If>     
    </If>
  );
};

export default SignIn;
