import React, { useState, useEffect } from "react";
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { Auth } from "aws-amplify";
import { useRouter } from 'next/router';
// import { SignUp, Greetings } from "aws-amplify-react";

import ForgotPassword from './components/forgot-password';
import SignIn from './components/sign-in';
import SidebarContent from './components/sidebar-content/SidebarContent';
import RequireNewPassword from './components/require-new-password';
import LandingPage from './components/landing-page';
import AuthPage from './components/auth-page';
import AuthContainer from "./components/auth-container/AuthContainer";
import LogoutDialog from './components/logout-dialog';

import { VERSIONS } from '../../utils/changelog';
import { useUserLogout } from '../../utils/use-user';
import get from 'lodash/fp/get';

const Root = styled.div`
  display: flex;
  flex: 1;
  // width: 100vw;

  > div {
    flex: 1;
  }
`;

const Sidebar = styled(SidebarContent)`

`;

const MyTheme = {
  button: { backgroundColor: "#2196f3" },
  a: { color: "#2196f3"},
  navButton: {backgroundColor: "#2196f3"}
};

const custom = {

}

const Authenticator = dynamic(
  () => import("aws-amplify-react").then(mod => mod.Authenticator),
  { ssr: false }
);



const SignedIn = ({ authState, children }) => {
  const router = useRouter();
  const { query } = router;
  if (query.dialog === 'privacy-policy' && authState === "signedIn") {
    router.push('/privacy-policy', '/?dialog=privacy-policy', { shallow: true })
    return null;
  }
  if (query.dialog === 'terms-of-service' && authState === "signedIn") {
    router.push('/terms-of-service', '/?dialog=terms-of-service', { shallow: true })
    return null;
  }
  return authState === "signedIn" ? children : null;
};

const PrivatePage = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const router = useRouter();
  const logoutUser = useUserLogout();
  const currentVersion = VERSIONS[0].version;
  const forceLogout = get('logout', VERSIONS[0]);
  const handleLogout = async () => {
    console.log('Forcing sign out');
    Auth.signOut();
    await router.push('/');
    localStorage.removeItem("persist:root")
    logoutUser();
    setOpenDialog(false);
  }
  useEffect(() => {
    const versionInLocalStorage = localStorage.getItem('version-panafold-connect');
    if (currentVersion !== versionInLocalStorage) {
      localStorage.setItem('version-panafold-connect', currentVersion);
      if(localStorage.getItem('amplify-authenticator-authState') === 'signedIn'){
          setOpenDialog(true);
      }
    }
  }, []);

  return(
    <Root>
      <Authenticator hideDefault={true} authState={"signIn"}>
        <LandingPage override={"SignUp"}/>
        <AuthContainer override={"SignIn" || "ForgotPassword" || "RequireNewPassword"}>
          <SignIn override={"SignIn"} />
          <ForgotPassword override={"ForgotPassword"} />
          <RequireNewPassword override={"RequireNewPassword"}/>
        </AuthContainer>
        <SignedIn override={"SignedIn"}>{children}</SignedIn>
      </Authenticator>
      <LogoutDialog open={openDialog} setOpenDialog={setOpenDialog} onSubmit={handleLogout} newVersion={currentVersion} forceLogout={forceLogout}/>
    </Root>
  );
};

export default PrivatePage;
