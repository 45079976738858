import React, {useState} from "react";
import styled from 'styled-components';
import get from 'lodash/fp/get';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

import FormDialog from '../../../../../shared/form-dialog';
import AlertForm from '../../../../../shared/alert-form';

import { Changes } from './components';
import {CURRENT_VERSION} from '../../../../../../utils/constants';

const seen_badges = {
    [`v-${CURRENT_VERSION}`]: true
}
const CustomBadge = styled(Badge)`
    .MuiBadge-badge {
        display: ${props => props.seen? 'none !important' : 'flex'}
    }
`;

const useStyles = makeStyles(theme => ({
    icon: {
        color: '#FFF'
    }
}));

const Changelog = () => {
    const seenBadge = Boolean(get(`v-${CURRENT_VERSION}`, JSON.parse(localStorage.getItem('seen_badges'))));
    const [open, setOpen] = useState(false);
    // const [seen, setSeen] = useState(Boolean(localStorage.getItem("seen_badge")));
    const [seen, setSeen] = useState(seenBadge);
    const classes = useStyles();

    const handleClick = () => {
        if(!seen) {
            // localStorage.setItem("seen_badge", true);
            localStorage.setItem("seen_badges", JSON.stringify(seen_badges));
            setSeen(true);
        }
        setOpen(true);
    }

    return (
    <div>
        <Tooltip title={`Version ${CURRENT_VERSION}`}>
            <IconButton aria-label="Changelog" onClick={() => handleClick()}>
                <CustomBadge color="error" variant="dot" seen={seen}>
                    <TrackChangesIcon color="inherit" className={classes.icon}/>
                </CustomBadge>
            </IconButton>
        </Tooltip>
        <Changes open={open} onCancel={() => setOpen(false)}/>
    </div>
    )
};

export default Changelog;
