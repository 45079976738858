import React from 'react';
import styled from "styled-components";
import clsx from 'clsx';
import NextLink from "next/link";
import { useRouter } from 'next/router';
import get from 'lodash/fp/get';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import MUIList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import MuiLink from '@material-ui/core/Link';
import ButtonBase from '@material-ui/core/ButtonBase';

import split from "lodash/split";
import includes from "lodash/includes";
import map from "lodash/map";

import Link from '../../../../../shared/link';
import { PRODUCT_PAGES, MATERIAL_PAGES } from '../../../../../../utils/constants';

const List = styled(MUIList)`
  ${props => props.disable && `
  display: none;
  `}
  
  .MuiListSubheader-sticky {
    position: unset;
  }
`;
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(4),
    },
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    expandIcon: {
      color: 'rgba(33, 33, 33,.5)',
    },
    icon: {
      // color: theme.palette.icon,
      color: 'rgba(33, 33, 33,.5)',
      width: 20,
      height: 20,
      minWidth: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    title: {
      fontSize: '13px',
      color: 'red !!important'
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    deepnested: {
      paddingLeft: theme.spacing(8),
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      fontWeight: 500,
      backgroundColor: 'rgb(247, 247, 247) !important',
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  }));

const Nav = ({ pages, className }) => {
    const navState = JSON.parse(localStorage.getItem('navState')) || {}; 
    const classes = useStyles();
    const [open, setOpen] = React.useState(navState);
    const router = useRouter()
    const handleClick = (idx) => {
        localStorage.setItem("navState", JSON.stringify({...open, [idx]: !open[idx]}));
        setOpen({...open, [idx]: !open[idx]});
    };
    // console.log(router);
    let routerValue = router.asPath;
    if(router.pathname.includes('pages')){
       const pageId = split(routerValue, '/', 3)[2];
       if(includes(map(MATERIAL_PAGES, 'id'), pageId)) routerValue = 'Materials'
       else if(includes(map(PRODUCT_PAGES, 'id'), pageId)) routerValue = 'Products'
      //  else routerValue = 'Products'
    }

    return(
      <React.Fragment>
        {/* Connect Type */}
        <For each="page" index="index" of={pages}>
          <List
            component="nav"
            disable={page.disable}
            disableSticky
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div"  id={`nested-list-subheader${index}`}>
                {page.title}
              </ListSubheader>
            }
            className={clsx(classes.root, className)}
          >
            {/* website: form submissions, pages */}
          <For each="item" index="idx" of={page.children}>
            <Choose>
              <When condition={item.hide}>
              </When>
              <When condition={item.children}>
                <ListItem button onClick={() => handleClick(idx)} disabled={item.disabled}>
                  <ListItemIcon className={classes.icon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title} 
                    disableTypography
                    classes={{
                      root: classes.title,
                    }}
                  />
                  {open[idx] ? <ExpandLess className={classes.expandIcon}/> : <ExpandMore className={classes.expandIcon}/>}
                </ListItem>
                <Collapse in={open[idx]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* pages: about-us, contact */}
                    <For each="child" index="cdx" of={item.children}>
                      <Choose>
                        <When condition={child.children}>
                          <ListItem button onClick={() => handleClick(cdx*10)} disabled={child.disabled} className={classes.nested}>
                            <ListItemText
                              primary={child.title}
                              disableTypography
                              classes={{
                                root: classes.title,
                              }}
                            />
                            {open[cdx*10] ? <ExpandLess className={classes.expandIcon}/> : <ExpandMore className={classes.expandIcon}/>}
                          </ListItem>
                          <Collapse in={open[cdx*10]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <For each="grandchild" of={child.children}>
                                <ListItem
                                  button
                                  component={Link}
                                  naked
                                  // href={grandchild.href}
                                  href={{
                                    pathname: grandchild.href,
                                    query: grandchild.query
                                  }}
                                  as={grandchild.as}
                                  // query={grandchild.query}
                                  passHref
                                  onClick={grandchild.onClick ? grandchild.onClick : undefined}
                                  // router.asPath === (mask ? mask : href)
                                  selected={routerValue === (grandchild.as? grandchild.as: grandchild.href)}
                                  classes={{
                                    selected: classes.active
                                  }}
                                  className={classes.deepnested}
                                  // disableGutters
                                  >
                                    <ListItemText
                                      primary={grandchild.title} 
                                      disableTypography
                                      classes={{
                                        root: classes.title,
                                      }}
                                    />
                                  </ListItem>
                              </For>
                            </List>
                          </Collapse>
                        </When>
                        <Otherwise>
                          <ListItem
                            button
                            component={Link}
                            naked
                            href={child.href}
                            as={child.as}
                            onClick={child.onClick ? child.onClick : undefined}
                            // router.asPath === (mask ? mask : href)
                            selected={routerValue === (child.as? child.title : child.href)}
                            classes={{
                              selected: classes.active
                            }}
                            className={classes.nested}
                            // disableGutters
                            >
                              <ListItemText
                                primary={child.title} 
                                disableTypography
                                classes={{
                                  root: classes.title,
                                }}
                              />
                            </ListItem>
                        </Otherwise>
                      </Choose>
                    </For>
                  </List>
              </Collapse>
              </When>
              <Otherwise>
                <ListItem
                  button
                  component={Link}
                  naked
                  href={item.href}
                  as={item.as}
                  // router.asPath === (mask ? mask : href)
                  selected={routerValue === (item.as? item.title : item.href)}
                  classes={{
                    selected: classes.active
                  }}
                  disabled={item.disabled}
                  // disableGutters
                  >
                    <ListItemIcon
                      className={classes.icon}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title} 
                      disableTypography
                      classes={{
                        root: classes.title,
                      }}
                    />
                  </ListItem>
              </Otherwise>
            </Choose>
          </For>
          </List>
        </For>
      </React.Fragment>
    )
};

export default Nav;
