import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import FlexDivider from '../../../../../../components/shared/flex-divider';
import { PipelineActions } from '../../../../../../components/drawers/pipeline-drawer/components';

 const ActionsContainer = ({ details, contentId, title, onClose, variant, ...props }) => {

  return (
    <Toolbar disableGutters sx={{ position: 'sticky', top: 0, backgroundColor: '#FFF', zIndex: 1055}}>
      <Typography variant="subtitle1" style={{fontWeight: 600, paddingLeft: 20 }}>{title}</Typography>
      <FlexDivider />
      <If condition={details === 'pipeline'}>
        <PipelineActions id={contentId} />
      </If>
      <IconButton 
        size="small" 
        aria-label="close" 
        onClick={() => onClose()}
        style={{
          backgroundColor: '#F2F2F2',
          marginRight: 10
        }}
      >
        <CloseIcon color="default"/>
      </IconButton>
    </Toolbar>
  );
};

export default ActionsContainer; 