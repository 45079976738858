import React from 'react';
import get from 'lodash/fp/get';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const CardTable = ({
  title,
  headers,
  data,
  showAdd,
  skeletonLines,
  isLoading,
  chip,
}) => {

  return(
    <Card 
      elevation={0} 
      style={{
        border: '1px solid #E7E7E7',
        borderRadius: '10px',
      }}
    >
      <Box display="flex" flexDirection="row">
        <CardHeader title={title} style={{paddingX: '10px'}} titleTypographyProps={{variant:'subtitle1'}} />
          {chip}
        </Box>
      <Divider />
      <Box width="calc(100%)">
        <Grid 
          container 
          spacing={2} 
          style={{
            paddingLeft: 15,
            paddingTop: 15,
            paddingBottom: 15,
            '> .MuiGrid-item': {
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 2
            }
          }}
        >
          <Choose>
            <When condition={isLoading}>
              {[...Array(skeletonLines).keys()].map(i => {
                return(
                <>
                  <Grid item xs={3}>
                    <Skeleton variant="text" style={{ fontSize: '1rem' }} />
                  </Grid>
                  <Grid item xs={9}>
                    <Skeleton variant="text" style={{ fontSize: '1rem' }} />
                  </Grid>
                </>
              )})}
            </When>
            <Otherwise>
              <For each="header" of={headers}>
                <If condition={get(`${get('id', header)}`,data) || showAdd }>
                  <Grid item xs={3.2} xl={3}>
                    <Typography variant="body1" color="rgb(140, 140, 140)" style={{wordBreak: 'break-word', ...get('style', header)}}>{`${header.label} :`}</Typography>
                  </Grid>
                  <Grid item xs={8} xl={9}>
                    <Typography variant="body1" style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>{data[header.id]}</Typography>
                  </Grid>
                </If>
              </For>
            </Otherwise>
          </Choose>
        </Grid>
      </Box>
    </Card>
  )
};

CardTable.defaultProps = {
  skeletonLines: 5
}

export default CardTable;