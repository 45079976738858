import styled, { css } from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const ContentWrapper = styled.div`
  max-width: 500px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StyledFormCard = styled(Paper)`
  padding: 15px;
  min-width: 400px;
`;

const withFormCardProps = (Component, props) => {
  return(rest) => (
    <Component {...props} {...rest} />
  )
};

export const FormCard = withFormCardProps(StyledFormCard, {
  elevation: 0
})

export const HeaderImage = styled.img`
  margin-bottom: 16px;
  width: fit-content;
  max-width: 180px;
  align-self: center;
`;

export const HeaderLabel = styled.span`
  color: #152939;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
`;

export const InputForm = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;
`;

export const ConfirmationForm = styled.form`
  background: white;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SignInButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-top: 20px;
  margin-bottom: 0;
  height: 45px;
  width: 100%
`;

