import { createMuiTheme } from "@material-ui/core/styles";

import palette from './palette';
import overrides from './overrides';
import props from './props';
import typography from './typography';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  props
});

export default theme;