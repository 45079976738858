import React, {useState} from "react";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { makeStyles } from '@material-ui/core/styles';
import { postDeploy } from '../../../../../../api';
import FormDialog from '../../../../../shared/form-dialog';
import AlertForm from '../../../../../shared/alert-form';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Chip, Icon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'action'
    }
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Schedule = () => {
    const [open, setOpen] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [dialog, updateDialog] = useState(null);

    const classes = useStyles();

    const handleDeploy = async () => {
        setDisableButton(true);
        const deployed = await postDeploy();
        if(deployed.status == 200) setOpen(true);
        updateDialog(null);
        setDisableButton(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    return (
    <div style={{marginRight: 10}}>
        <Tooltip title="Schedule Release">
            <Chip icon={<ScheduleIcon style={{ marginRight: '-18px'}}/>}  onClick={() => updateDialog("deploy-website")}/>
        </Tooltip>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Deployment Scheduled!
          </Alert>
        </Snackbar>
        <FormDialog
            Form={AlertForm}
            id="deploy-website"
            title="Deploy website"
            initialValues={{ companyName: "Flexform" }}
            open={dialog === "deploy-website"}
            onCancel={() => updateDialog(null)}
            onSubmit={handleDeploy}
            disableButton={disableButton}
            submitLabel="Yes, deploy"
        />
    </div>

    )
};

export default Schedule;
