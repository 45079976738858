import React, { useState } from 'react';
import { Auth } from "aws-amplify";
import { Field, Form } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import styled from 'styled-components';

import Button from "../../../shared/button";
import InputField from '../../../shared/input-field';

import ErrorMessage from '../error-message';
import { ContentWrapper, InputForm, ConfirmationForm, FormCard } from '../../styles';
import { required, minPasswordLength, composeValidators, matches } from '../../validators';
import SidebarContent from '../sidebar-content/SidebarContent';
import { rest } from 'lodash';
import SnackProgress from '../../../shared/snack-progress';
import AuthPage from '../auth-page';

const Root = styled.div`
  display: flex;
`

const RequireNewPassword = ({ authState, onStateChange, authData }) => {
  let error = null;
  const [progress, setProgress] = useState(null);
  const onSubmit = async ({ password, name }) => {
    setProgress({
			text: 'Changing password...',
			severity: 'info'
		});
    try{
      const data = await Auth.completeNewPassword(
        authData.user,
        password,
        {
          name: name
        }
      );
      setProgress({
				text: 'Password updated successfully!',
				severity: 'success'
      });
      setTimeout(() => {
        setProgress(null);
        onStateChange("signIn");
      }, 2000);
    } catch(e) {
      error = e.message;
      setProgress({
				text: 'An error ocurred.',
				severity: 'error'
			});
    }
  }

  return (
    <If condition={authState === 'RequireNewPassword'}>
      {/* <SidebarContent /> */}
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <ContentWrapper>
            <FormCard>
            <ConfirmationForm onSubmit={handleSubmit}>
              <Typography variant="h5">Change Password</Typography>
              <Typography variant="subtitle">Please enter your new password below.</Typography>
              <Field
                name="name"
                fullWidth
                component={InputField}
                label="Name"
                margin="normal"
                validate={required}
              />
              <Field
                name="password"
                fullWidth
                component={InputField}
                label="New Password"
                margin="normal"
                type="password"
                validate={composeValidators(required, minPasswordLength)}
              />
              <Field
                name="confirmpassword"
                fullWidth
                component={InputField}
                label="Confirm New Password"
                margin="normal"
                type="password"
                validate={matches}
              />
              <ErrorMessage message={error} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                mt={3}
                mb={1}
              >
                Submit new password
              </Button>
              <div>
                Already have an account?
                <Button
                  color="primary"
                  onClick={() => onStateChange("signIn", {})}
                >
                  Sign In
                </Button>
              </div>
            </ConfirmationForm>
            </FormCard>
          </ContentWrapper>
        )}
      </Form>
      <If condition={progress}>
        <SnackProgress progress={progress} />
      </If>
    </If>
  );
};

export default RequireNewPassword;
