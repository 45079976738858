import { normalize } from "normalizr";

import groupBy from "lodash/groupBy";
import merge from 'lodash/merge';

import {
  Page,
  Collection,
  Product,
  Asset,
  Home,
  Contact,
  AboutUs,
  TradeProgram
} from "../redux/schemas/cms";

export const normalizeEntries = (items) => {
    const result = groupBy(items, (item) => {
      return item.sys.contentType.sys.id;
    });
    // console.log(result)

    const pages = normalize(result.page, [Page]);
    const collections = normalize(result.collections, [Collection]);
    const products = normalize(result.product, [Product]);
    const homepage = normalize(result.homepage, [Home]);
    const contact = normalize(result.contact, [Contact]);
    const aboutUs = normalize(result.aboutUs, [AboutUs]);
    const tradeProgram = normalize(result.tradeProgram, [TradeProgram]);
    return merge({}, pages, collections, products, homepage, contact, aboutUs, tradeProgram);
}

export const normalizeAssets = (items) => {
  // console.log(items);
  const assets = normalize(items, [Asset]);
  console.log(assets);
  return assets;
}

export default normalizeEntries;