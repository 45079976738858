import { colors } from '@material-ui/core';
import indigo from "@material-ui/core/colors/indigo";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import color from '@material-ui/core/colors/amber';

const white = '#FFFFFF';
const black = '#000000';


export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[700],
    light: colors.blue[100]
  },
  secondary: {
    main: green[500]
  },
  tertiary:{
    main: colors.blue[500]
  },
  error: {
    main: red[600]
  },
  text: {
    // primary: colors.indigo[900]
    // primary: grey[700],
    // secondary: '#000000',
    dark: '#000000',
    contrastText: white
  },
  background: {
    default: '#F9FAFC',
    paper: white
  },
  neutral: {
    main: '#111827',
    light: '#374151',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827'
  }
};