import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const VersionContainer = styled.div`
    // color: black;
    font-weight:
`;

const Title = styled(Typography)`
    padding-bottom: 20px;
    color: black;
`;

const Header = styled.div`
    font-weight: 500;
    color: black;
`;

const List = styled.ul`
    margin-bottom: 50px;
`;

const ListItem = styled.li`
`
const Trello = styled.iframe`

`;

const Board = ({
    open,
    onCancel,
  }) => {
      return (
          <Dialog
            open={open}
            fullScreen
          >
            <DialogTitle>Roadmap</DialogTitle>
            <DialogContent>
            <Trello src="https://trello.com/b/JT9WhEvj.html" frameBorder="0" width="100%" height="100%"></Trello>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} variant="contained" color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
      );
  };

  export default Board;