import React, {useState} from 'react';
import { Auth } from "aws-amplify";
import { Field, Form } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import styled from 'styled-components';

import Button from "../../../shared/button";
import InputField from '../../../shared/input-field';

import ErrorMessage from '../error-message';
import { ContentWrapper, InputForm, ConfirmationForm, FormCard } from '../../styles';
import { required, minPasswordLength, composeValidators } from '../../validators';
import SidebarContent from '../sidebar-content/SidebarContent';
import AuthPage from '../auth-page';
import SnackProgress from '../../../shared/snack-progress';

const Root = styled.div`
  display: flex;
`

const ForgotPassword = ({ authState, onStateChange }) => {
  const [progress, setProgress] = useState(null);
  let error = null;

  const onSubmit = async ({ email, password, code }, form) => {
    if (authState === 'forgotPassword') {
      try {
        await Auth.forgotPassword(email);
        setTimeout(form.reset);
        setProgress({
          text: 'Confirmation code sent!',
          severity: 'info'
        });
        setTimeout(() => {
          onStateChange("forgotPasswordSubmit");
          setProgress(null);
        }, 2000);
        
      } catch (e) {
        error = e.message;
        setProgress({
          text: e.message,
          severity: 'error'
        });
        setTimeout(() => setProgress(null), 2000);
      }
    } else if (authState === "forgotPasswordSubmit") {
      try {
        setProgress({
          text: 'Verifying...',
          severity: 'info'
        });
        await Auth.forgotPasswordSubmit(email, code, password);
        setTimeout(form.reset);
        setProgress({
          text: 'Password Updated!',
          severity: 'success'
        });
        setTimeout(() => {
          onStateChange('signIn')
          setProgress(null);
        }, 2000);
      } catch(e) {
        error = 'Sorry wrong code!'
        setProgress({
          text: e.message,
          severity: 'error'
        });
        setTimeout(() => setProgress(null), 2000);
      };
    }
  }

  return (
    <If condition={authState === "forgotPassword" || authState === "forgotPasswordSubmit"}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Choose>
              <When condition={authState === "forgotPassword"}>
                <ContentWrapper>
                  <FormCard>
                    <InputForm onSubmit={handleSubmit}>
                      <Typography variant="h6" style={{marginBottom: 32}}>Forgot Password</Typography>
                      <Field
                        name="email"
                        fullWidth
                        component={InputField}
                        label="Email"
                        margin="normal"
                        validate={required}
                      />
                      <ErrorMessage message={error} />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        mt="auto"
                        mb={1}
                      >
                        Send confirmation code
                      </Button>
                      <div>
                        Already have an account?
                        <Button
                          color="primary"
                          onClick={() => onStateChange("signIn", {})}
                        >
                          Sign In
                        </Button>
                      </div>
                    </InputForm>
                  </FormCard>
                </ContentWrapper>
              </When>
              <When condition={authState === "forgotPasswordSubmit"}>
                <ContentWrapper>
                  <FormCard>
                    <ConfirmationForm onSubmit={handleSubmit}>
                      <Field
                        name="email"
                        fullWidth
                        component={InputField}
                        label="Email"
                        margin="normal"
                        validate={required}
                      />
                      <Field
                        name="password"
                        fullWidth
                        component={InputField}
                        label="New Password"
                        margin="normal"
                        type="password"
                        validate={composeValidators(required, minPasswordLength)}
                      />
                      <Field
                        name="code"
                        fullWidth
                        component={InputField}
                        label="Confirmation Code"
                        margin="normal"
                        validate={required}
                      />
                      <ErrorMessage message={error} />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        mt={3}
                        mb={1}
                      >
                        Submit new password
                      </Button>
                      <div>
                        Already have an account?
                        <Button
                          color="primary"
                          onClick={() => onStateChange("signIn", {})}
                        >
                          Sign In
                        </Button>
                      </div>
                    </ConfirmationForm>
                  </FormCard>
                </ContentWrapper>
              </When>
            </Choose>
          )}
        </Form>
        <If condition={progress}>
          <SnackProgress progress={progress} />
        </If> 
    </If>
  );
};

export default ForgotPassword;
