import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import includes from 'lodash/includes';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, useMediaQuery } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import PageIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MapIcon from '@material-ui/icons/Map';
import PieChartIcon from '@material-ui/icons/PieChart';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { onCreateDownloadFileForm, onCreateContactUsForm, onCreateTradeProgramForm, onCreateSubmissionForm } from '../../../../src';
import { useUnseenDownloadFileForms } from '../../../../utils/crm/use-download-file-form';
import { useUnseenContactUsForms } from '../../../../utils/crm/use-contact-us-form';
import { useUnseenTradeProgramForms } from '../../../../utils/crm/use-trade-program-form';
import { useUnseenSubmissionForms } from '../../../../utils/crm/use-submission-form';
import { useUserSession, useUpdateNotification } from '../../../../utils/crm/use-user-session';
import { useGetUnassignedContacts } from '../../../../utils/crm/use-contact';
import { useGetUnassignedCompanys } from '../../../../utils/crm/use-company';
import { isExpiredGoogleCalendarToken } from '../../../../utils/crm/use-google-calendar';
import { 
  useOnCreateGroupContact, 
  useOnDeleteGroupContact, 
  useOnCreateGroupCompany, 
  useOnDeleteGroupCompany, 
  useOnCreateContact,
  useOnDeleteContact,
  useOnUpdateContact,
  useOnCreateCompany,
  useOnDeleteCompany,
  useOnUpdateCompany,
  useOnCreateEvent,
  useOnUpdateEvent,
  useOnDeleteEvent,
} from '../../../../utils/crm/use-subscription';
import { useUserGroups } from '../../../../utils/use-user';

import { Nav } from './components';
import TokenDialog from '../../../calendar/components/token-dialog';
import { VERSIONS } from '../../../../utils/changelog.js';
import { CURRENT_ENV } from '../../../../utils/constants';

const CURRENT_VERSION = VERSIONS[0].version;

const seen_badges = {
  [`v-${CURRENT_VERSION}`]: true
}

// const disableNav = Boolean(CURRENT_ENV == 'production');
const disableNav = false;

const CustomTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`;

const CustomBadge = styled.div`
  ${props => !props.display && `
    display: none;
  `}
  background-color: #1976d2;
  border-radius: 6px;
  color: white;
  min-width: 30px;
  height: 20px;
  // padding: 0px 0px;
  padding-top:2px;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
  font-size:11px;
  font-weight: 600;

`;

// const CustomBadge = styled(Chip)`
//   ${props => !props.display && `
//     display: none;
//   `}
//   // background-color: #e53935;
//   border-radius: 6px;
//   // color: white;
//   // min-width: 30px;
//   // padding: 2px 2px;
//   // text-align: center;
//   // font-size:
// `;

// const CustomBadge = styled(({ color, children, ...other }) => (
//   <Chip color="primary" size="small" {...other} label="v1.0">v1.0</Chip>
// ))`
//   ${props => !props.display && `
//     display: none;
//   `}
//   border-radius: 6px;
// `;

const useStyles = makeStyles(theme => ({
  drawer: {
    // width: 256,
    // [theme.breakpoints.up('md')]: {
    //   marginTop: 64,
    //   height: 'calc(100% - 64px)'
    // },
    // paddingTop: '16px',
    // border: '1px solid green'
    backgroundColor: '#111827',
    color: '#FFFFFF',
    width: 256
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // border: '1px solid red',
    // paddingTop: '16px',
    // marginBottom: '32px'
    // padding: theme.spacing(2)
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  bottomNav: {
    margin: 'auto',
    marginBottom: 0
  }
}));

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const classes = useStyles();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });
  
  const [submissionCount, setSubmissionCount] = useState({
    contactCount: 0,
    downloadCount: 0,
    tradeProgramCount: 0,
    newsletterCount: 0,
    addContactCount: 0
  });
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [{ unassigned: unassignedContacts }] = useGetUnassignedContacts(triggerFetch);
  const [{ unassigned: unassignedCompanys }] = useGetUnassignedCompanys(triggerFetch);
  const [userEmail, setUserEmail] = useState(null);

  const groups = useUserGroups();
  const isManager = includes(groups, 'manager');
  const isAdmin = includes(groups, 'admin');
  const isEditor = includes(groups, 'editor');
  const isSales = includes(groups, 'sales');
  
  const seenBadge = Boolean(get(`v-${CURRENT_VERSION}`, JSON.parse(localStorage.getItem('seen_badges'))));

  const [openTokenDialog, setOpenTokenDialog] = useState(false);
  const isExpired = isExpiredGoogleCalendarToken();
  useEffect(() => {
    if (isExpired) setOpenTokenDialog(true);
  }, [isExpired]);

  const fetchUnseenSubmissions = async () => {
    const user = await Auth.currentUserInfo();
    const { email } = user.attributes;
    setUserEmail(email);
    const response = await useUserSession(email);
    const downloadResponse = await useUnseenDownloadFileForms(response.lastDownloadNotificationSeen);
    const contactResponse = await useUnseenContactUsForms(response.lastContactNotificationSeen);
    const tradeProgramResponse = await useUnseenTradeProgramForms(response.lastTradeProgramNotificationSeen);
    const newsletterResponse = await useUnseenSubmissionForms(response.lastNewsletterNotificationSeen, 'NEWSLETTER');
    const addContactResponse = await useUnseenSubmissionForms(response.lastAddContactNotificationSeen, 'ADDCONTACT');
    console.log("====== fetching =======");
    setSubmissionCount((prevState) => ({
      contactCount: contactResponse.listContactUsForms.items.length,
      downloadCount: downloadResponse.listDownloadFileForms.items.length,
      tradeProgramCount: tradeProgramResponse.listTradeProgramForms.items.length,
      newsletterCount: newsletterResponse.listSubmissionForms.items.length,
      addConctactCount: addContactResponse.listSubmissionForms.items.length,
    }));
  };

  const updateNotification = async (formName) => {
    const timestamp = new Date().toISOString();
    await useUpdateNotification(userEmail, timestamp, formName); 
  }

  useEffect(() => {
    fetchUnseenSubmissions();
    const downloadSubscription = API.graphql(graphqlOperation(onCreateDownloadFileForm)).subscribe({
      next: () => {
        setSubmissionCount((prevState) => ({
          contactCount: prevState.contactCount,
          downloadCount: prevState.downloadCount + 1,
          tradeProgramCount: prevState.tradeProgramCount,
          newsletterCount: prevState.newsletterCount,
          addContactCount: prevState.addContactCount
        }));
      },
      error: (error) => {
        console.log(error)
      }
    });

    const contactSubscription = API.graphql(graphqlOperation(onCreateContactUsForm)).subscribe({
      next: () => {
        setSubmissionCount((prevState) => ({
          contactCount: prevState.contactCount + 1,
          downloadCount: prevState.downloadCount,
          tradeProgramCount: prevState.tradeProgramCount,
          newsletterCount: prevState.newsletterCount,
          addContactCount: prevState.addContactCount
        }));
      },
      error: (error) => {
        console.log(error)
      }
    });
    
    const tradeProgramSubscription = API.graphql(graphqlOperation(onCreateTradeProgramForm)).subscribe({
      next: () => {
        setSubmissionCount((prevState) => ({
          contactCount: prevState.contactCount,
          downloadCount: prevState.downloadCount,
          tradeProgramCount: prevState.tradeProgramCount + 1,
          newsletterCount: prevState.newsletterCount,
          addContactCount: prevState.addContactCount
        }));
      },
      error: (error) => {
        console.log(error)
      }
    });

    const newsletterSubscription = API.graphql(graphqlOperation(onCreateSubmissionForm)).subscribe({
      next: (data) => {
        if(data.value.data.onCreateSubmissionForm.type === 'NEWSLETTER'){
          setSubmissionCount((prevState) => ({
            contactCount: prevState.contactCount,
            downloadCount: prevState.downloadCount,
            tradeProgramCount: prevState.tradeProgramCount,
            newsletterCount: prevState.newsletterCount + 1,
            addContactCount: prevState.addContactCount
          }));
        }
      },
      error: (error) => {
        console.log(error)
      }
    });

    const addContactSubscription = API.graphql(graphqlOperation(onCreateSubmissionForm)).subscribe({
      next: (data) => {
        if(data.value.data.onCreateSubmissionForm.type === 'ADDCONTACT'){
          setSubmissionCount((prevState) => ({
            contactCount: prevState.contactCount,
            downloadCount: prevState.downloadCount,
            tradeProgramCount: prevState.tradeProgramCount,
            newsletterCount: prevState.newsletterCount,
            addContactCount: prevState.addContactCount + 1
          }));
        }
      },
      error: (error) => {
        console.log(error)
      }
    });

    const createContactSubscription = useOnCreateContact(() => setTriggerFetch(prev => !prev));
    const updateContactSubscription = useOnUpdateContact(() => setTriggerFetch(prev => !prev));
    const deleteContactSubscription = useOnDeleteContact(() => setTriggerFetch(prev => !prev));

    const createCompanySubscription = useOnCreateCompany(() => setTriggerFetch(prev => !prev));
    const updateCompanySubscription = useOnUpdateCompany(() => setTriggerFetch(prev => !prev));
    const deleteCompanySubscription = useOnDeleteCompany(() => setTriggerFetch(prev => !prev));

    const createGroupContactSubscription = useOnCreateGroupContact();
    const deleteGroupContactSubscription = useOnDeleteGroupContact();

    const createGroupCompanySubscription = useOnCreateGroupCompany();
    const deleteGroupCompanySubscription = useOnDeleteGroupCompany();

    const createEventSubscription = useOnCreateEvent();
    const updateEventSubscription = useOnUpdateEvent();
    const deleteEventSubscription = useOnDeleteEvent();

    return () => {
      downloadSubscription.unsubscribe();
      contactSubscription.unsubscribe();
      tradeProgramSubscription.unsubscribe();
      newsletterSubscription.unsubscribe();
      addContactSubscription.unsubscribe();
      createGroupContactSubscription.unsubscribe();
      deleteGroupContactSubscription.unsubscribe();
      createGroupCompanySubscription.unsubscribe();
      deleteGroupCompanySubscription.unsubscribe();
      createContactSubscription.unsubscribe();
      updateContactSubscription.unsubscribe();
      deleteContactSubscription.unsubscribe();
      createCompanySubscription.unsubscribe();
      updateCompanySubscription.unsubscribe();
      deleteCompanySubscription.unsubscribe();
      createEventSubscription.unsubscribe();
      updateEventSubscription.unsubscribe();
      deleteEventSubscription.unsubscribe();
    }
  }, []);

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardOutlinedIcon />
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Products',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Authentication',
      href: '/sign-in',
      icon: <LockOpenIcon />
    },
    {
      title: 'Typography',
      href: '/typography',
      icon: <TextFieldsIcon />
    },
    {
      title: 'Icons',
      href: '/icons',
      icon: <ImageIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];
  const { contactCount, downloadCount, tradeProgramCount, newsletterCount, addContactCount } = submissionCount;
  // console.log("count=====")
  // console.log(submissionCount)
  const pages2 = [
    {
      title: 'Reports',
      children: [
        {
          title: 'Dashboard',
          href: '/dash',
          icon: <DashboardOutlinedIcon />
        }
      ]
    },
    {
      title: 'Management',
      disable: disableNav || (!isAdmin && !isSales),
      children: [
        {
          title: 
            <CustomTitle>
              Contacts
              <CustomBadge
                display={unassignedContacts.length > 0}
              >
                {unassignedContacts.length}
              </CustomBadge>
            </CustomTitle>,
          href: '/customers/contacts',
          disabled: disableNav,
          icon: <PeopleOutlineOutlinedIcon />
        },
        {
          title: 
            <CustomTitle>
              Companies
              <CustomBadge
                display={unassignedCompanys.length > 0}
              >
                {unassignedCompanys.length}
              </CustomBadge>
            </CustomTitle>,
          href: '/customers/companies',
          disabled: disableNav,
          icon: <BusinessOutlinedIcon />
        },
        {
          title: 
            <CustomTitle>
              Pipeline
            </CustomTitle>,
            href: '/management/pipeline',
            // disabled: disableNav,
            icon: <LinearScaleIcon />
        },
        {
          title: 
            <CustomTitle>
              Calendar
            </CustomTitle>,
          href: '/customers/calendar',
          disabled: disableNav,
          icon: <DateRangeIcon />
        },
        {
          title: 
            <CustomTitle>
              Tasks
            </CustomTitle>,
          href: '/management/tasks',
          disabled: disableNav,
          icon: <AssignmentIcon />
        },
      ]
    },
    {
      title: 'Website',
      children: [
        {
          title: 'Pages',
          hide: !isManager && !isAdmin && !isEditor,
          // hide: true,
          icon: <PageIcon/>,
          children: [
            {
              title: 'Home',
              href: '/website/pages/home'
            },
            // {
            //   title: 'Products',
            //   href: '/pages/[page_id]/collections/[collection_id]',
            //   as: '/pages/7uruqd0qREdvDGT6mvJSmM/collections/undefined',
            // },
            {
              title: 'In Stock',
              children: [
                {
                  title: 'Quick Ship',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/tJ8akeWSeBSzkFKBArXzR',
                  // query: {id: 'tJ8akeWSeBSzkFKBArXzR'}
                },
                {
                  title: 'LA Display Sale',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/6exBqMJ7w2K0tRs0tj1Chk'
                },
                {
                  title: 'SF Display Sale',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/7Hh2uknER2ny9schS7b18R'
                },
                {
                  title: 'Warehouse Sale',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/29txgAqIXnkhQBD5we5Plq'
                }
              ]
            },
            {
              title: 'Products',
              children: [
                {
                  title: 'Indoor',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/7uruqd0qREdvDGT6mvJSmM',
                  // query: {id: '7uruqd0qREdvDGT6mvJSmM'}
                },
                {
                  title: 'Outdoor',
                  href: '/website/pages/products/[page_id]',
                  as: '/website/pages/products/EE5Y8KNxQfKh9YkGaXHrb',
                  // query: {id: 'EE5Y8KNxQfKh9YkGaXHrb'}
                }
              ]
            },
            // {
            //   title: 'Materials',
            //   href: '/pages/[page_id]/collections/[collection_id]',
            //   as: '/pages/7EfVcaWufa4v65NW1WDY7F/collections/undefined',
            // },
            {
              title: 'Materials',
              children: [
                {
                  title: 'Indoor',
                  href: '/website/pages/materials/[page_id]',
                  as: '/website/pages/materials/7EfVcaWufa4v65NW1WDY7F'
                },
                {
                  title: 'Outdoor',
                  href: '/website/pages/materials/[page_id]',
                  as: '/website/pages/materials/4s9LbIiKUejeGeN6420fcb'
                }
              ]
            },
            {
              title: 'About Us',
              children: [
                {
                  title: 'Our Story',
                  href: '/website/pages/about-us/our-story'
                },
                {
                  title: 'Trade Program',
                  href: '/website/pages/about-us/trade-program'
                }
              ]
            },
            {
              title: 'Contact',
              href: '/website/pages/contact',
            }
          ]
        },
        {
          title: 'Form Submissions',
          // disabled: disableNav,
          hide: !isManager && !isAdmin && !isSales,
          icon: 
            <Badge
              color="error"
              variant="dot"
              invisible={contactCount == 0 && downloadCount == 0 && tradeProgramCount == 0 && newsletterCount == 0 && addContactCount == 0}
            >
              <ListOutlinedIcon />
            </Badge>
          ,
          children: [
            {
              title: 
                <CustomTitle>
                  Contact us
                  <CustomBadge
                    display={contactCount > 0}
                  >
                    {contactCount}
                  </CustomBadge>
                </CustomTitle>
              ,
              href: '/website/form-submissions/contact-form',
              onClick: async () => {
                updateNotification('contact-form');
                setSubmissionCount((prevState) => ({
                  contactCount: 0,
                  downloadCount: prevState.downloadCount,
                  tradeProgramCount: prevState.tradeProgramCount,
                  newsletterCount: prevState.newsletterCount,
                  addContactCount: prevState.addContactCount
                }));

              }
            },
            {
              title: 
                <CustomTitle>
                  Download 
                  <CustomBadge
                    display={downloadCount > 0}
                  >
                    {downloadCount}
                  </CustomBadge>
                </CustomTitle>
              ,
              href: '/website/form-submissions/download-form',
              onClick: async () => {
                await updateNotification('download-form');
                setSubmissionCount((prevState) => ({
                  contactCount: prevState.contactCount,
                  downloadCount: 0,
                  tradeProgramCount: prevState.tradeProgramCount,
                  newsletterCount: prevState.newsletterCount,
                  addContactCount: prevState.addContactCount
                }));
              }
            },
            {
              title: 
                <CustomTitle>
                  Trade Program 
                  <CustomBadge
                    display={tradeProgramCount > 0}
                  >
                    {tradeProgramCount}
                  </CustomBadge>
                </CustomTitle>
              ,
              href: '/website/form-submissions/trade-program-form',
              onClick: async () => {
                 await updateNotification('trade-program-form');
                 setSubmissionCount((prevState) => ({
                   contactCount: prevState.contactCount,
                   downloadCount: prevState.downloadCount,
                   tradeProgramCount: 0,
                   newsletterCount: prevState.newsletterCount,
                   addContactCount: prevState.addContactCount
                 }));
              }
            },
            {
              title: 
                <CustomTitle>
                  Newsletter
                  <CustomBadge
                    display={newsletterCount > 0}
                  >
                    {newsletterCount}
                  </CustomBadge>
                </CustomTitle>
              ,
              href: '/website/form-submissions/newsletter-form',
              onClick: async () => {
                 await updateNotification('newsletter-form');
                 setSubmissionCount((prevState) => ({
                   contactCount: prevState.contactCount,
                   downloadCount: prevState.downloadCount,
                   tradeProgramCount: prevState.tradeProgramCount,
                   newsletterCount: 0,
                   addContactCount: prevState.addContactCount
                 }));
              }
            },
            {
              title: 
                <CustomTitle>
                  Add Contact
                  <CustomBadge
                    display={addContactCount > 0}
                  >
                    {addContactCount}
                  </CustomBadge>
                </CustomTitle>
              ,
              href: '/website/form-submissions/add-contact-form',
              onClick: async () => {
                 await updateNotification('add-contact-form');
                 setSubmissionCount((prevState) => ({
                   contactCount: prevState.contactCount,
                   downloadCount: prevState.downloadCount,
                   tradeProgramCount: prevState.tradeProgramCount,
                   newsletterCount: prevState.newsletterCount,
                   addContactCount: 0
                 }));
              }
            }
          ]
        }
      ]
    },
    {
      title: 'Connect App',
      children: [
        {
          title: 
          <CustomTitle>
            Changelog
            <Badge
              variant="dot"
              color="error"
              invisible={seenBadge}
            >
              <CustomBadge
                display={true}
              >
                v{CURRENT_VERSION}
              </CustomBadge>
            </Badge>
          </CustomTitle>
          ,
          icon: <MapIcon/>,
          href: '/changelog'
        }
      ]
    }
  ]

  const pages3 = [
    {
      // title: '',
      children: [
        {
          title: 'Privacy Policy',
          href: '/privacy-policy',
          as: '/?dialog=privacy-policy'
        },
        {
          title: 'Terms of Service',
          href: '/terms-of-service',
          as: '/?dialog=terms-of-service'
        }
      ]
    }
  ];
  if (isAdmin) {
    const analyticsPage = pages2[0].children
    analyticsPage.push(
      {
        title: 'Analytics',
        href: '/analytics',
        icon: <PieChartIcon />
      }
    );
  };

  if(lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        classes={{
          paper: classes.drawer
        }}
        variant="permanent"
      >
        
      </Drawer>
    )
  }
};

export default Sidebar;
