import React from "react";

export default () => (
  <React.Fragment>
    Effective as of  December 21, 2020
    <br /><br />
    Welcome to the Panafold Terms of Use agreement. For purposes of this agreement, “Site” refers to the Company’s website, which can be accessed at Panafold.co . “Service” refers to the Company’s services accessed via the Site. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service.
    <br /><br />
    The following Terms of Use apply when you view or use the Service via our website located at Panafold.co or by accessing the Service through clicking on the Connect Application (the “App”) on any device.
    <br /><br />
    Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service.
    <br /><br />
    Services provided by us may only be used for lawful purposes. You agree to comply with all
    applicable laws, rules, and regulations in connection with your use of the services. Any material
    or conduct that in our judgment violates this policy in any manner may result in suspension or
    termination of the services or removal of user’s account with or without notice.
    <br /><br />
    <strong>Prohibited use</strong>
    <br /><br />
    You may not use the services to publish content or engage in activity that is illegal under
    applicable law, that is harmful to others, or that would subject us to liability, including, without
    limitation, in connection with any of the following, each of which is prohibited under this AUP:
    • Phishing or engaging in identity theft
    • Distributing computer viruses, worms, Trojan horses, or other malicious code
    • Distributing pornography or adult related content or offering any escort services
    • Promoting or facilitating violence or terrorist activities
    • Infringing the intellectual property or other proprietary rights of others
    <br /><br />
    <strong>Enforcement</strong>
    <br /><br />
    Your services may be suspended or terminated with or without notice upon any violation of this
    policy. Any violations may result in the immediate suspension or termination of your account.
    <br /><br />
    <strong>Reporting violations</strong>
    <br /><br />
    To report a violation of this policy, please contact us. We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
    <br /><br /><br /><br />
  </React.Fragment>
);