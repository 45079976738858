import NextApp from "next/app";
import Head from "next/head";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { Fragment } from "react";
import withRedux from "next-redux-wrapper";
import Amplify from 'aws-amplify';
import awsconfig from '../src/aws-exports';
import { PersistGate } from 'redux-persist/integration/react';
import "react-image-gallery/styles/css/image-gallery.css";
import "suneditor/src/assets/css/suneditor-contents.css";
import PrivatePage from '../components/private-page';
import SidebarPage from '../components/sidebar-page';
import ProtectedRoute from '../components/protected-route';
import { Auth } from "aws-amplify";
import LogRocket from 'logrocket';
import get from 'lodash/fp/get';

// import { persistStore } from 'redux-persist';
// import { Authenticator } from 'aws-amplify-react';
// const Authenticator = dynamic(
//   () => import("aws-amplify-react").then(mod => mod.Authenticator),
//   { ssr: false }
// );

import { Provider } from "react-redux";
import {
  ThemeProvider as SCThemeProvider,
  createGlobalStyle
} from "styled-components";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import createReduxStore from "../utils/create-redux-store";
import muiTheme from "../utils/theme";
// import muiTheme from "../utils/theme-backup";

import { DialogProvider, DrawerProvider } from '../providers';
import { SnackbarProvider } from 'notistack';
import ErrorSnackbar from "../components/shared/error-snackbar/ErrorSnackbar";

const theme = {
  colors: {
    primary: "#0070f3"
  }
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: #f4f6f8 !important;
    background-color: red;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9e9e9e;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  #__next {
    // height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

if(process.env.NODE_ENV === 'production'){
  LogRocket.init('cnsbg8/panafold-connect');
}
Amplify.configure(awsconfig);

console.log(process.env.NODE_ENV);

class App extends NextApp {

  static async getInitialProps({Component, ctx}) {

    // we can dispatch from here too
    // ctx.store.dispatch({type: 'FOO', payload: 'foo'});

    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

    return {pageProps};

  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;

    const getLayout = Component.getLayout ?? ((page) => page);
    
    return (
      <SCThemeProvider theme={theme}>
        <MUIThemeProvider theme={muiTheme}>
          <Fragment>
            <Head>
              <title>Panafold Connect</title>
            </Head>
            <GlobalStyle />
            <CssBaseline />
            <Provider store={store}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                Components={{
                  error: ErrorSnackbar
                }}>
                <DialogProvider>
                  <DrawerProvider>
                    <PersistGate persistor={store.__PERSISTOR} loading={null}>
                      <PrivatePage>
                        <ProtectedRoute>
                          {/* <SidebarPage>
                            <Component {...pageProps} /> 
                          </SidebarPage> */}
                            {getLayout(<Component {...pageProps} />)}
                        </ProtectedRoute>
                      </PrivatePage>
                    </PersistGate>
                  </DrawerProvider>
                </DialogProvider>
              </SnackbarProvider>
            </Provider>
          </Fragment>
        </MUIThemeProvider>
      </SCThemeProvider>
    );
  }
}

export default withRedux(createReduxStore)(App);
