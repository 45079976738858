import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const VersionContainer = styled.div`
    // color: black;
    // font-weight:
    margin-bottom: 50px;
`;

const Title = styled(Typography)`
    padding-bottom: 20px;
    color: black;
    text-decoration: underline;
`;

const Header = styled.div`
    font-weight: 500;
    font-size: .86rem !important;
    color: black;
`;

const List = styled.ul`
    margin-bottom: 20px;
    font-size: .86rem !important;
`;

const ListItem = styled.li`
`
/*
    Types of changes
    - 'Added' for new features.
    - 'Changed' for changes in existing functionality.
    - 'Deprecated' for soon-to-be removed features.
    - 'Removed' for now removed features.
    - 'Fixed' for any bug fixes.
    - 'Security' in case of vulnerabilities.
*/

const Changes = ({
    open,
    onCancel,
  }) => {
      return (
          <Dialog
            open={open}
            scroll="paper"
          >
            <DialogTitle>Change Log</DialogTitle>
            <DialogContent dividers={true}>
          {/* <DialogContentText
            tabIndex={-1}
          > */}
          <VersionContainer>
            <Title variant="h4">Version 1.8.1</Title>
            <Header>Fixed</Header>
              <List>
                <ListItem>Dashboard activity shows the proper height for each timeline item</ListItem>
              </List>
            <Header>Changed</Header>   
              <List>
                <ListItem>Collapsed drawer icon moved to left of top appbar.</ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.8.0</Title>
              <Header>Added</Header>
              <List>
                <ListItem>Email activity in dashboard</ListItem>
                <ListItem>Option to deauthorize gmail settings from Connect App</ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>Contact table contact name is now first name + last name</ListItem>
                <ListItem>Contact table contact name is bolder + clickable</ListItem>
                <ListItem>Company table company name is bolder + clickable</ListItem>
                <ListItem>Email address in details page opens up composer</ListItem>
                <ListItem>Web addresses open in a new tab</ListItem>
                <ListItem>Dashboard notes and emails details have a light color blue box for clarity</ListItem>
                <ListItem>Form labels updated to 'Save' instead of 'Edit'</ListItem>
                <ListItem>'Assigned to' changed to 'Assign to' in table actions</ListItem>
                <ListItem>Updated gmail authorization process. Made simpler</ListItem>
              </List>
              <Header>Fixed</Header>
              <List>
                <ListItem>Issues with email signature resolved</ListItem>
                <ListItem>Rerendering issues in activity after sending email</ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.7.0</Title>
              <Header>Added</Header>
              <List>
                <ListItem>Activity Dashboard: View team activity in the dashboard tab. Currently listing notes, contact and company creation and deletion. Can filter by employee</ListItem>
                <ListItem>Add name in my account tab. You can change your display name</ListItem>
                <ListItem>Gmail integration: Authenticate gmail in settings tab and compose and send emails to contacts/companies</ListItem>
                <ListItem>Email Activity: Emails sent will be logged in contact/company activity</ListItem>
                <ListItem>User signature: Add email signatures in settings tab</ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>Restrict delete to admin only</ListItem>
                <ListItem>Login page UI update</ListItem>
                <ListItem>Contact/Company Input labels are now bolder and slightly bigger</ListItem>
                <ListItem>Company Action card tabs are now bolder and slighly bigger</ListItem>
              </List>
              <Header>Deprecated</Header>
              <List>
                <ListItem>Display name will soon change from email to users name in my account</ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.6.0</Title>
              <Header>Added</Header>
              <List>
                <ListItem>
                  Company notes. 
                </ListItem>
                <ListItem>
                  Batch operations in contact and company tables. Look for "Actions" button after selecting rows.
                </ListItem>
                <ListItem>
                  Companies with emails can now sync to mailchimp.
                </ListItem>
                <ListItem>
                  Mailchimp subscribe/unsubscribe button
                </ListItem>
                <ListItem>
                  Helper text added to contact and company forms around groups that are synced with mailchimp.
                </ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Hide "entered by" in notes when no value exists. (import)
                </ListItem>
                <ListItem>
                  Create company process during contact creation now selects after creating.
                </ListItem>
                <ListItem>
                  Mailchimp and group sync. Contact/Company do not get automatically removed from group when unsubscribed. 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.5.0</Title>
              <Header>Added</Header>
              <List>
                <ListItem>
                  Contacts and Company detailed filtering.
                </ListItem>
                <ListItem>
                  Mailchimp activity in contact details page
                </ListItem>
                <ListItem>
                  Unassigned icon, alert and filter
                </ListItem>
                <ListItem>
                  VIP status badge
                </ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Update contact and company details UI. Added cards
                </ListItem>
                <ListItem>
                  Updated contact table with popover Contact card.
                </ListItem>
              </List>
              <Header>Fixed</Header>
              <List>
                <ListItem>
                  Style classes were not being passed down
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.6</Title>
              <Header>Fix</Header>
              <List>
                <ListItem>
                  Reference missing in progress page after creating a product or material
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.5</Title>
              <Header>Fix</Header>
              <List>
                <ListItem>
                  Link search now shows all products or materials 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.4</Title>
              <Header>Note</Header>
              <List>
                <ListItem>
                  CRM items disabled in production.
                </ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Split groups into three types: mailings, region, industry.
                </ListItem>
              </List>
              <Header>Added</Header>
              <List>
                <ListItem>
                  Note field added to products/materials. This will show up on the website next to "request more info".
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.3</Title>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Re-enabled edit and delete note.
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.2</Title>
            <Header>Fixed</Header>
              <List>
                <ListItem>
                  Fixed issue with updating and deleting contact
                </ListItem>
                <ListItem>
                  Fixed issue with routing to contact detail page after create
                </ListItem>
                <ListItem>
                  Fixed issue with mailchimp subscriptions
                </ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Updated contact details page ui and notes form. Added Activity card to display notes and created at.
                </ListItem>
                <ListItem>
                  Temporarily disabled edit and delete note. Expect it back in the next release. 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.1</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Added State/zip to download form table
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.4.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Enabled form submissions from 33SIXTY websites to be viewed. 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.3.1</Title>
            <Header>Fixed</Header>
              <List>
                <ListItem>
                  Deployment Button was not triggering a deployment to website
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.3.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Product Preview: see a preview of a product before publishing
                </ListItem>
              </List>
              <Header>Changed</Header>
              <List>
                <ListItem>
                  Sidebar UI has changed to help with scaling
                </ListItem>
                <ListItem>
                  Appbar with actions moved to top
                </ListItem>
                <ListItem>
                  UI Style changes throughout pages for scaling
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.2.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Homepage Content: is now manageable and can be sorted by drag and drop
                </ListItem>
                <ListItem>
                  Contact Page Content: is now manageable
                </ListItem>
                <ListItem>
                  About Us Page Content: is now manageable
                </ListItem>
              </List>
              <Header>Fixed</Header>
              <List>
                <ListItem>
                  Spec/CAD File title missing causing issue in database
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.1.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Materials are now separated by Indoor and Outdoor 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h4">Version 1.0.10</Title>
            <Header>Fixed</Header>
              <List>
                <ListItem>
                  Under the hood improvements to API and Data
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
              <Title variant="h4">Version 1.0.9</Title>
              <Header>Fixed</Header>
              <List>
                <ListItem>
                  PDFs were titled as 'untitled' in database.
                </ListItem>
              </List>
            </VersionContainer>
            <VersionContainer>
              <Title variant="h4">Version 1.0.8</Title>
              <Header>Added</Header>
              <List>
                <ListItem>
                  Material Images: materials now have a new image limit of 20.
                </ListItem>
                <ListItem>
                  Form layout: changed to accomodate new image limit.
                </ListItem>
                <ListItem>
                  Loading: animation now shows when content is loading.
                </ListItem>
              </List>
            </VersionContainer>

            <VersionContainer>
              <Title variant="h4">Version 1.0.7</Title>
              <Header>Added</Header>
              <List>
                  <ListItem>Crop Images: When adding a new image. An edit button will appear allowing a 3:2 aspect ratio crop. You can only do this
                    when adding an image. The option will not show after the image has been published. If the original image is smaller than the crop box,
                    no crop will be made.
                  </ListItem>
              </List>
            </VersionContainer>
            <VersionContainer>
            <Title variant="h4">Version 1.0.6</Title>
            <Header>Added</Header>
            <List>
                <ListItem>Link Product: You can now link a product from the collection page</ListItem>
            </List>
            <Header>Fixed</Header>
            <List>
                <ListItem>Year/Description: Now leaving year and description empty will save correctly</ListItem>
                <ListItem>Sort Alphabetically: Adding or modifying a product now sorts the collection alphabetically</ListItem>
                <ListItem>Seeing Multiple: An error with the cache was showing multiples of products. After signing out the cache will clear.</ListItem>
            </List>
            </VersionContainer>

            <VersionContainer>
            <Title variant="h4">Version 1.0.5</Title>
            <Header>Added</Header>
            <List>
                <ListItem>Change Log to keep track of what has been fixed or added.</ListItem>
            </List>
            </VersionContainer>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Close
          </Button>
        </DialogActions>
        </Dialog>
      );
  };

  export default Changes;