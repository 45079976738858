import React, { useEffect, useState } from 'react';
import { useRouter } from "next/router";
import { Auth } from 'aws-amplify';
import get from 'lodash/fp/get';

import some from 'lodash/some';

import { SALEROUTES, EDITORROUTES, ADMINROUTES } from '../../utils/route-permissions';

import { AccessDenied, Loading } from './components';

const ProtectedRoute = ({ children }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async() => {
      setIsLoading(true);
      let data = await Auth.currentAuthenticatedUser();
      setUser(data);
      setIsLoading(false);
    }
    fetchUser();
  }, []);

   if(isLoading) return (<Loading />)

  if(user){
    const groups = get('signInUserSession.accessToken.payload.cognito:groups', user, []);
    const protectedSalePage = some(SALEROUTES, el => router.asPath.includes(el));
    const protectedEditorPage = some(EDITORROUTES, el => router.asPath.includes(el));
    const protectedAdminPage = some(ADMINROUTES, el => router.asPath.includes(el));

    if(protectedSalePage|| protectedEditorPage || protectedAdminPage){
      if(groups){
        if(groups.includes('manager') || groups.includes('admin')) return(<>{children}</>);
        else if(!groups.includes('sales') && protectedSalePage) return <AccessDenied/>;
        else if(!groups.includes('editor') && protectedEditorPage) return <AccessDenied/>;
        else if(!groups.includes('admin') && protectedAdminPage) return <AccessDenied/>;
      }
      else{
        console.log('no permissions...')
        return <AccessDenied/>;
      }
    }
    
  }

  return (
    <>
      {children}
    </>
  )
}

export default ProtectedRoute;