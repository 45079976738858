import React from 'react';
import styled from 'styled-components';
import { useRouter } from "next/router";
import Link from "next/link";

import MuiButton from '@material-ui/core/Button';
// import { CURRENT_VERSION } from '../../../../utils/constants';

import { PrivacyPolicy, TermsOfService } from '../auth-page/components';
import { VERSIONS } from '../../../../utils/changelog';

import "tailwindcss/tailwind.css";
 
const CURRENT_VERSION = VERSIONS[0].version;

const Root = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: #1D76D3;
`;

const PatternIMG = styled.img`
  height: 100vh;
  width: 50%;

  @media screen and (max-width: 2000px) {
    width: 40%;
  }

  @media screen and (max-width: 1500px) {
    width: 30%;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const Logo = styled.img`
  margin-top: 75px;
  width: 100%;
  max-width: 75px;
  object-fit: contain;

  @media screen and (max-width: 900px) {
    max-width: 60px;
  }
`;

const Container = styled.div`
  max-width: 500px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  // border: 1px solid red;

  @media screen and (max-width: 1000px) {
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    align-items: center;
    text-align: center;
  }
`;

const FlexDivider = styled.span`
	flex: 1;
`;

const Footing = styled.span`
  height: 25%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 75px;
  // margin-bottom: 100px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: #FFF;
  line-height: initial;

  @media screen and (max-width: 1500px) {
    font-size: 75px;
  }

`;

const Subtitle = styled.div`
  font-size: 24px;
  margin-bottom: 60px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: #FFF;
  line-height: 46px;

  @media screen and (max-width: 1500px) {
    font-size: 24px;
  }

`;

const Links = styled.div`
  color: #FFF important;
  margin-bottom: 75px;
  // border: 1px solid red;

`;

const Button = styled(MuiButton)`
  color: #FFF !important;
  margin-right: 35px;
`

const LandingPage = ({ authState, onStateChange }) => {
  const router = useRouter();
  const { query } = router;
  // console.log(router)
  // console.log(query)

  return (
    <If condition={authState === "signUp"}>
      <div className="bg-white">
        <main>
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 pt-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span class="sr-only">Connect</span>
                  {/* <img class="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-teal-200-cyan-400.svg" alt="" /> */}
                  <img class="h-8 w-auto sm:h-8" src="static/images/logo/connect-panafold-logo.png" alt="Connect App" />
                </a>
              </div>
            </div>
            <div class="hidden md:flex md:items-center md:space-x-6">
              <button onClick={() => onStateChange("signIn", {})} class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                Login
              </button>
            </div>
          </nav>
          {/* Hero Section */}
          <div class="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
            <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
              <div>
                <div>
                  {/* <img class="h-11 w-auto" src="static/images/logo/connect-panafold-logo.png" alt="Connect App" /> */}
                </div>
                <div class="mt-20">
                  <div class="mt-6 sm:max-w-xl">
                    <h1 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    <span class="text-blue-500">Connect</span> your website and your customers all on a single platform
                    </h1>
                    <p class="mt-6 text-xl text-gray-500">
                    Connect with your clients. Perfect your sales and service. One simple interface that respects you and your team's needs.
                    </p>
                  </div>
                  {/* <form action="#" class="mt-12 sm:max-w-lg sm:w-full sm:flex">
                    <div class="min-w-0 flex-1">
                      <label for="hero_email" class="sr-only">Email address</label>
                      <input id="hero_email" type="email" class="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-rose-500 focus:ring-rose-500" placeholder="Enter your email" />
                    </div>
                    <div class="mt-4 sm:mt-0 sm:ml-3">
                      <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-rose-500 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10">Notify me</button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
            {/* Image */}
            <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
              <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div class="hidden sm:block">
                  <div class="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
                  <svg class="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392">
                    <defs>
                      <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                  </svg>
                </div>
                <div class="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                  <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none" src="static/images/dashboard-mock.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* Mission Section */}
          <div class="py-12 bg-white">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="lg:text-center">
                {/* <h2 class="text-base text-blue-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  All-in-one-platform
                </p>
                <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Keep it simple and intuitive: Unite your business needs all in one location
                </p>
              </div>

              <div class="mt-10">
                <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  {/* Item 1 */}
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt class="text-lg leading-6 font-medium text-gray-900">
                      Content Management 
                      </dt>
                      <dd class="mt-2 text-base text-gray-500 ml-0">
                      Create, manage and modify digital products/content. We build a website from scratch and our code handles connecting your products/content to your site.
                      </dd>
                    </div>
                  </div>
                  {/* Item 2 */}
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt class="text-lg leading-6 font-medium text-gray-900">
                      Customer Management
                      </dt>
                      <dd class="mt-2 text-base text-gray-500 ml-0">
                      Grow your business by staying connected with your clients. We automate processes and tasks to improve performance and help visualize your client activity.
                      </dd>
                    </div>
                  </div>
                  {/* Item 3 */}
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt class="text-lg leading-6 font-medium text-gray-900">
                        Form Submissions
                      </dt>
                      <dd class="mt-2 text-base text-gray-500 ml-0">
                      Automatically send your website form submissions to our Connect platform and forward to any provided emails.
                      </dd>
                    </div>
                  </div>
                  {/* Item 4 */}
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt class="text-lg leading-6 font-medium text-gray-900">
                      Website Design and Host
                      </dt>
                      <dd class="mt-2 text-base text-gray-500 ml-0">
                      Tell us your website needs and we will build a site for your business. We code it and hook it up to your Connect Platform.
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          {/* Integration Section */}
          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <img class="h-16 w-auto" src="static/images/logo/Gmail-logo.png" alt="Gmail" />
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                      Gmail Integration
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                    Stay on top of customer support and reach clients directly from their contact profile. Save time and stay focussed: Compose an email directly from the platform. We securely send emails from your authorized account.
                    </p>
                    <div class="mt-6">
                      {/* <a href="#" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                        Get started
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="static/images/connect-gmail-mock.png" alt="Gmail Integration"/>
                </div>
              </div>
            </div>
          </div>


          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <img class="h-16 w-auto" src="static/images/logo/MC_Logo.jpg" alt="Mailchimp" />
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                      Mailchimp Integration
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                    Keep your mailchimp audience up to date with custom Connect groups. Sync contacts and companies across mailchimp and Connect.
                    </p>
                    <div class="mt-6">
                      {/* <a href="#" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                        Get started
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="pl-4 -mr-48 sm:pr-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="static/images/connect-mailchimp-mock.png" alt="Mailchimp Integration"/>
                </div>
              </div>
            </div>
          </div>
          {/* CTA Section */}
          <div class="relative mt-24 sm:mt-32 sm:py-16">
            <div aria-hidden="true" class="hidden sm:block">
              <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
              <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
                <defs>
                  <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
              </svg>
            </div>
            <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div class="relative rounded-2xl px-6 py-10 bg-blue-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                  <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                    <path class="text-blue-400 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                    <path class="text-blue-600 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                  </svg>
                </div>
                <div class="relative">
                  <div class="sm:text-center">
                    <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                      Contact us to sign up.
                    </h2>
                    <p class="mt-6 mx-auto max-w-2xl text-lg text-blue-200">
                      Email <a class="font-bold text-white no-underline" href="mailto:connect@panafold.com">connect@panafold.com</a> Thank you.
                    </p>
                  </div>
                  {/* <form action="#" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                    <div class="min-w-0 flex-1">
                      <label for="cta_email" class="sr-only">Email address</label>
                      <input id="cta_email" type="email" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500" placeholder="Enter your email">
                    </div>
                    <div class="mt-4 sm:mt-0 sm:ml-3">
                      <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10">Notify me</button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer class="mt-24 bg-gray-900 sm:mt-12">
          <div class="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
              <div class="px-5 py-2 ">
                <a href="https://www.panafold.co/" target="_blank" class="text-base text-gray-400 hover:text-gray-300 no-underline">
                  About Panafold
                </a>
              </div>

              <div class="px-5 py-2">
                <Link href={{ pathname: '/', query: { dialog: 'privacy-policy' }}}>
                  <a  class="text-base text-gray-400 hover:text-gray-300 no-underline">
                    Privacy Policy
                  </a>
                </Link>
                
              </div>

              <div class="px-5 py-2">
                <Link href={{ pathname: '/', query: { dialog: 'terms-of-service' }}}>
                  <a href="#" class="text-base text-gray-400 hover:text-gray-300 no-underline">
                    Terms of Service
                  </a>
                </Link>
              </div>

              <div class="px-5 py-2">
                <a href="https://www.panafold.co/about" target="_blank" class="text-base text-gray-400 hover:text-gray-300 no-underline">
                  Our Team
                </a>
              </div>

              <div class="px-5 py-2">
                <a href="https://www.panafold.co/panafoldcontact"  target="_blank" class="text-base text-gray-400 hover:text-gray-300 no-underline">
                  Contact Us
                </a>
              </div>
            </nav>
            
            <p class="mt-8 text-center text-base text-gray-400">
              &copy; 2021 Panafold, Inc. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
      <PrivacyPolicy open={query.dialog === 'privacy-policy'} onClose={() => router.push("/")} />
      <TermsOfService open={query.dialog === 'terms-of-service'} onClose={() => router.push("/")} />
    </If>
  )
}

export default LandingPage