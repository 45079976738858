import { useState } from 'react';
import get from 'lodash/fp/get';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import { useGetEmployee } from '../../../utils/crm/use-employee';

const UpdatedActivityCard = ({ content, isLoading }) => {

  const fieldType = get('description', content);
  const field = get('phase', content);
  const creatorId = content.editedBy ? content.editedBy[0].id : null;
  const [{ employee: creatorUser }] = useGetEmployee(creatorId);
  const creatorName = get('name', creatorUser);
  const time = moment(get('createdAt', content)).format("h:mm a");
  const date = moment(get('createdAt', content)).format("MMM D, YYYY");

  const [anchorEl, setAnchorEl] = useState(null);

  return(
    <Card style={{
      backgroundColor: 'rgb(244, 246, 248)',
      padding: 15,
      marginTop: 5,
      marginBottom: 5,
      position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
    }}
    elevation={0}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          padding: 4,
          borderRadius: '20%',
          backgroundColor: '#e0e0e0',
          marginRight: 16,
          marginTop: 2
        }}
      >
        <AutorenewOutlinedIcon fontSize='small'/>
      </div>
      <Box display={"flex"} flexDirection={"column"}>
        <Choose>
          <When condition={isLoading}>
            <Skeleton variant="text" width={150}/>
          </When>
          <Otherwise>
            <Typography variant="subtitle2" style={{paddingBottom: 4}}>
              <strong>{fieldType}</strong> updated to
              {` ${field.label}`}
            </Typography>
          </Otherwise>
        </Choose>
        <Typography variant="caption">{date} at {time}</Typography>
        <If condition={creatorName}>
          <Typography variant="caption">by {creatorName}</Typography>
        </If>
      </Box>
    </Card>

  )
};

export default UpdatedActivityCard;