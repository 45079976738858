import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Router from 'next/router';
import { useRouter } from "next/router";
import clsx from 'clsx';

import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { Topbar, Sidebar } from './components';
// import Sidebar from "../sidebar";

// const MainLayoutRoot = styled('div');

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 64,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 256,
    // backgroundColor: '#f4f6f8'
    backgroundColor: '#F7F9FC'
  },
  content: {
    height: '100%'
  }
}));

const SidebarPage = ({ children }) => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const shouldOpenSidebar = isDesktop ? true : sidebarOpen;

  // return (
  //     <div
  //     className={clsx({
  //       [classes.root]: true,
  //       [classes.shiftContent]: isDesktop
  //     })}
  //   >
  //     <Topbar onSidebarOpen={handleSidebarOpen}/>
  //     <Sidebar 
  //     onClose={handleSidebarClose}
  //     open={shouldOpenSidebar}
  //     variant={isDesktop ? 'persistent' : 'temporary'}
  //     />
  //     <main className={classes.content}>
  //       {children}
  //     </main>
  //   </div>
  // );
  return(
    <>
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop
        })}
        >
        <Topbar onSidebarOpen={() => setSidebarOpen(true)} />
        <Sidebar
          onClose={() => setSidebarOpen(false)}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <Box
          style={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
        </Box>
        
      </div>
    </>
  )
};

export default SidebarPage;