import React, { useState, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from "notistack";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const ErrorSnackbar = React.forwardRef(
  (
    { id, message, errors },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent
        ref={ref}
        style={{
          "@media (min-width:600px)": {
            minWidth: "344px !important",
          },
        }}
      >
        <Card style={{ backgroundColor: "#d32f2f", width: "100%" }}>
          <CardActions
            style={{ padding: "8px 8px 8px 16px", justifyContent: "space-between" }}
          >
            <Typography variant="body2" style={{ color: "#FFF" }}>
              {message}
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="Show more"
                size="small"
                style={{
                  padding: "8px 8px",
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  color: "#FFF",
                  transition: "all .2s",
                }}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton
                size="small"
                style={{
                  padding: "8px 8px",
                  transform: "rotate(0deg)",
                  color: "#FFF",
                  transition: "all .2s",
                }}
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Paper style={{ backgroundColor: "#fff", padding: 2 }}>
              {errors?.length > 0 ? (
                errors.map((error, index) => (
                  <Typography
                    key={index}
                    paragraph
                    gutterBottom
                    variant="caption"
                  >
                    {error.message}
                  </Typography>
                ))
              ) : (
                <Typography paragraph gutterBottom variant="caption">
                  {errors?.message}
                </Typography>
              )}
            </Paper>
          </Collapse>
        </Card>
      </SnackbarContent>
    );
  }
);

export default ErrorSnackbar;