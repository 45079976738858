import React from "react";
import styled from "styled-components";
import UploadSVG from '../../../../static/images/undraw_upload.svg'

const Root = styled.div`
    display: flex;
    width: 461px;
    // height: 100%;
    background-color: #F5F6F8;
    flex-direction: column;
`;

const Header = styled.div`
    // border: 1px solid red;


    padding: 64px 64px 30px;
`;

const PanafoldLogo = styled.img`
  width: 100%;
  max-width: 168px;
//   max-height: 500px;
  object-fit: contain;
//   border: 1px solid red;
`;

const CompanyLine = styled.div`
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    color: #000;
    padding-top: 40px;
`;

const Drawing = styled.div`
    // border: 1px solid green;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: auto;
    // margin-bottom: 100px;
    // justify-cobtebtL cebter
`;

const UploadDrawing = styled(UploadSVG)`
    // max-width: 300px;
    width: 90%;
    max-height: 300px;
    // border: 1px solid black;
    // margin: auto;
    // margin-bottom: 100px;
`;

const SidebarContent = () => (
    <Root>
        <Header>
            <PanafoldLogo src={"/static/images/Panafold-Connect-Logo.png"} />
            <CompanyLine>Content and customer <br/> management all on a <br/> single platform</CompanyLine>
        </Header>
        <Drawing>
            <UploadDrawing />
        </Drawing>
    </Root>
);

export default SidebarContent;