import { schema } from "normalizr";
import assign from "lodash/assign";
import get from 'lodash/get';


const pageProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  const version = value.sys.version
  const name = value.fields.name['en-US'];
  const collections = value.fields.collections ? value.fields.collections['en-US'] : [];
  return {
    id, version, name, collections
  }
};

const collectionProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  switch(value.sys.type) {
    case 'Link': {
      return {id}
    }
    case 'Entry': {
      const version = value.sys.version;
      const name = get(value, 'fields.name[en-US]');
      const products = get(value, 'fields.products[en-US]');
      return { id, version, name , products }
    }
    default: return value
  }
}

const productProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  switch(value.sys.type) {
    case 'Link': {
      return {id}
    }
    case 'Entry': {
      const version = value.sys.version;
      const name = get(value, 'fields.name[en-US]');
      const year = get(value, 'fields.year[en-US]');
      const designer = get(value, 'fields.designer[en-US]');
      const brand = get(value, 'fields.brand[en-US]');
      const description = get(value, 'fields.description[en-US]');
      const thumbnail = get(value, 'fields.thumbnail[en-US]') || {sys:{id: '0'}};
      const images = get(value, 'fields.images[en-US]') || [];
      const spec = get(value, 'fields.spec[en-US]');
      const cad = get(value, 'fields.cad[en-US]');
      const note = get(value, 'fields.note[en-US]');
      const type = get(value, 'fields.type[en-US]');
      return { id, version, name, year, brand, designer, description, note, thumbnail, images, cad, spec, type }
    }
    default: return value
  }
}

const assestProcessStrategy = (value) => {
  // console.log(value);
  const id = value.sys.id;
  if(!value.sys.type) return {id};
  
  switch(value.sys.type) {
    case 'Link': {
      return {id}
    }
    case 'Asset': {
      const version = value.sys.version;
      const title = get(value, 'fields.title[en-US]');
      const imageUrl = get(value, 'fields.file[en-US].url');
      const imageName = get(value, 'fields.file[en-US].fileName');
      return { id, version, title, imageUrl, imageName }
    }
    default: return value
  }
}

const homeProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  const version = value.sys.version
  const name = value.fields.name['en-US'];
  const products = value.fields.products ? value.fields.products['en-US'] : [];
  const activateSales = get(value, 'fields.activateSales[en-US]');
  const showSfSalePage = get(value, 'fields.showSfSalePage[en-US]');
  const showLaSalePage = get(value, 'fields.showLaSalePage[en-US]');
  const title = value.fields.title ? value.fields.title['en-US'] : '';
  const subtitle = value.fields.subtitle ? value.fields.subtitle['en-US'] : '';
  const background = value.fields.background ? value.fields.background['en-US'] : {};
  const bannerLinkType = value.fields.bannerLinkType ? value.fields.bannerLinkType['en-US'] : '';
  return {
    id, version, name, products, activateSales, showSfSalePage, showLaSalePage, title, subtitle, background, bannerLinkType
  }
};

const contactProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  const version = value.sys.version;
  const fields = Object.keys(value.fields).reduce((acc, cur) => {
    acc[cur] = value.fields[cur]['en-US'];
    return acc;
  }, {});
  return {
    id, 
    version, 
    fields
  }
};

const tradeProgramProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  const version = value.sys.version;
  const fields = Object.keys(value.fields).reduce((acc, cur) => {
    acc[cur] = value.fields[cur]['en-US'];
    return acc;
  }, {});
  return {
    id, 
    version, 
    fields
  }
};

const aboutUsProcessStrategy = (value, parentObject, key) => {
  const id = value.sys.id;
  const version = value.sys.version;
  const name = get(value, 'fields.name[en-US]');
  const title = get(value, 'fields.title[en-US]');
  const subtitle = get(value, 'fields.subtitle[en-US]');
  const description = get(value, 'fields.description[en-US]');
  const images = get(value, 'fields.images[en-US]') || [];
  return { id, version, name, title, subtitle, description, images };
};

export const Asset = new schema.Entity('assets',{},{
  idAttribute: value => value.sys.id,
  processStrategy: assestProcessStrategy
  // processStrategy: value => assign({}, value.fields)
});

export const Product = new schema.Entity('products',{
  thumbnail: Asset,
  images: [Asset],
  spec: Asset,
  cad: Asset
},{
  idAttribute: value => value.sys.id,
  processStrategy: productProcessStrategy
});

export const Collection = new schema.Entity('collections',{
  products: [Product]
},{
  idAttribute: value => value.sys.id,
  processStrategy: collectionProcessStrategy
  //  processStrategy: value => assign({}, value.fields)
});

export const Page = new schema.Entity('pages', {
  collections: [Collection]
}, {
  idAttribute: value => value.sys.id,
  processStrategy: pageProcessStrategy
});

export const Home = new schema.Entity('home', {
  products: [Product]
}, {
  idAttribute: value => value.sys.id,
  processStrategy: homeProcessStrategy
});

export const AboutUs = new schema.Entity('aboutUs', {
  images: [Asset],
}, {
  idAttribute: value => value.sys.id,
  processStrategy: aboutUsProcessStrategy
});

export const Contact = new schema.Entity('contact', {}, {
  idAttribute: value => value.sys.id,
  processStrategy: contactProcessStrategy
});

export const TradeProgram = new schema.Entity('tradeProgram', {}, {
  idAttribute: value => value.sys.id,
  processStrategy: tradeProgramProcessStrategy
});