import React from 'react';
import { useRouter } from "next/router";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import UndrawSVG from '../../../../static/images/undraw_starry_window.svg';


const DeniedDrawing = styled(UndrawSVG)`
    width: 80%;
    @media screen and (max-width: 960px) {
      width: unset;
      max-height: 200px;
    }
`;

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  code: {
    fontSize: '80px',
    color: '#FFF',
    lineHeight: '100px'
  },
  title: {
    fontSize: '24px',
    color: '#333334',
    lineHeight: '24px'
  },
  subtitle: {
    fontSize: '16px',
    color: '#C1C2C3',
    margin: '16px 0px'
  },
  message: {
    paddingLeft: 100,
    margin: 'auto',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingLeft: '0'
    }
  },
  drawing: {
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

const AccessDenied = () => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box
      position="absolute" 
      width="100vw" 
      display="flex" 
      margin="auto" 
      top="0"
      left="0"
      height="100vh" 
      justifyContent="center" 
      alignItems="center" 
      flexDirection="column" 
      bgcolor="#F4F5FA"
    >
      <Grid container className={classes.root}>
        <Grid item className={classes.message} xs={12} sm={6}>
          <Typography variant="h1" className={classes.code}>403</Typography>
          <Typography variant="h4" className={classes.title}>Access Denied</Typography>
          <Typography variant="h6" className={classes.subtitle}>You don't have permission to access requested page.</Typography>
          <Button variant="contained" color="primary" onClick={() => router.replace('/dash')}>Go Home</Button>
        </Grid>
        <Grid item className={classes.drawing} xs={12} sm={6}>
          <DeniedDrawing />
        </Grid>
      </Grid>
    </Box>
)};

export default AccessDenied;