import React, { useState } from 'react';
import { Auth } from "aws-amplify";
import get from "lodash/fp/get";

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';

import AlertForm from "../../../../../../../../shared/alert-form";
import FormDialog from '../../../../../../../../shared/form-dialog';
import AuthorizeGoogleCalendarForm from './AuthorizeGoogleCalendarForm';

import { GOOGLE_CALENDAR_AUTH_URL } from '../../../../../../../../../api/googleCalendar';
import { useJwtToken } from '../../../../../../../../../utils/use-user';
import { 
	useGetAuth, 
	useListGoogleCalendars, 
	useSyncCalendars, 
	useDeauthorizeGoogleCalendar 
} from '../../../../../../../../../utils/crm/use-google-calendar';
import { useListCalendarsByUser, useCalendars } from '../../../../../../../../../utils/crm/use-calendar';

const useStyles = makeStyles((theme) => ({
	loading: {
		minHeight: 150,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	table: {
		marginTop: theme.spacing(3),
		'& .MuiTableCell-root': {
			borderBottom: 'none',
			padding: '8px 8px 8px 0'
		}
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	authorizeBtn: {
		width: 'fit-content',
		marginTop: theme.spacing(2)
	}
}));

const checkDuplicates = values => {
	const freq = {};
	for (let key in values) {
		if (values[key].willSync == true) {
			const connectCalendarId = get('curConnectCalendar.id', values[key]);
			if (!connectCalendarId) return;
			if (freq[connectCalendarId]) {
				freq[connectCalendarId]++;
			} else {
				freq[connectCalendarId] = 1;
			}
		}
	}
	for (let key in freq) {
		if (freq[key] > 1) {
			return true
		}
	}
	return false;
};

const SyncForm = ({ googleCalendars, connectCalendars, onSubmit, userId }) => {
	const [{ calendars: allCalendars }] = useCalendars();
	const initialValues = googleCalendars.reduce((acc, cur) => {
		const prevConnectCalendar = connectCalendars.find(item => item.googleCalendarId === cur.id) || null;
		const googleChannelId = get('googleChannelId', prevConnectCalendar) || null;
		const googleResourceId = get('googleResourceId', prevConnectCalendar) || null;
		acc[cur.id] = {
			googleId: cur.id,
			googleName: cur.summary,
			googlePrimary: cur.primary || null,
			prevConnectCalendar,
			curConnectCalendar: prevConnectCalendar,
			willSync: prevConnectCalendar ? true : false,
			googleChannelId,
			googleResourceId
		};
		return acc;
	}, {});
	const getPrevOtherUserCalendar = (googleCalendarId) => {
		return allCalendars.find(calendar => calendar.googleCalendarId === googleCalendarId && calendar.calendarUserId !== userId);
	};
	const classes = useStyles();
	const [values, setValues] = useState(initialValues);
	const handleChange = (googleId) => (event, value, reason) => {
		setValues(prev => ({
			...prev,
			[googleId]: {
				...prev[googleId],
				curConnectCalendar: value ? value : null,
				willSync: value ? true : false,
			}
		}))
	};
	const getHelperText = (googleCalendarId) => {
		const otherUserCalendar = getPrevOtherUserCalendar(googleCalendarId);
		if (otherUserCalendar) {
			return (
				<Typography>
					This Google Calendar already syncs with Connect Calendar <em>{otherUserCalendar.name}</em>
				</Typography>
			)
		} else {
			return null;
		}
	};
	return (
		<Box>
			<Typography><b>Sync Calendars</b></Typography>
			<Table className={classes.table}>
				<TableBody>
					<TableRow>
						<TableCell>
							<Typography color="primary"><b>Google Calendars</b></Typography>
						</TableCell>
						<TableCell align="left">
							<Typography color="primary"><b>Connect Calendars</b></Typography>
						</TableCell>
					</TableRow>
					<For each="item" index="index" of={googleCalendars}>
						<TableRow key={item.id}>
							<TableCell align="left" className="label">
								<If condition={item.primary}><Typography variant="caption">Primary calendar</Typography></If>
								<Typography>{item.summary}</Typography>
							</TableCell>
							<TableCell align="right">
								<Choose>
									<When condition={getPrevOtherUserCalendar(item.id)}>
										{getHelperText(item.id)}
									</When>
									<Otherwise>
										<Autocomplete
											id={item.id}
											value={values[item.id].curConnectCalendar}
											options={connectCalendars}
											onChange={handleChange(item.id)}
											fullWidth
											getOptionLabel={option => option.primary ? `${option.name} (primary calendar)` : option.name}
											renderInput={params => <TextField {...params} variant="outlined" placeholder="Select" />}
										/>
									</Otherwise>
								</Choose>
							</TableCell>
						</TableRow>
					</For>
				</TableBody>
			</Table>
			<Button
				color="primary"
				disableFocusRipple
				className={classes.authorizeBtn}
				variant="contained"
				onClick={() => onSubmit(values)}
				disabled={checkDuplicates(values)}
			>
				Save Settings
			</Button>
			<If condition={checkDuplicates(values)}>
				<Typography style={{ paddingTop: '8px' }} color="error">Connect Calendar selected in more than one Google Calendar</Typography>
			</If>
		</Box>
	)
};

const GoogleCalendar = ({ setProgress }) => {
	const classes = useStyles();
	const userId = Auth.user.username;
	const jwtToken = useJwtToken();
	const [{ calendars: googleCalendars, loading }] = useListGoogleCalendars(userId, jwtToken);
	const [{ calendars: connectCalendars }] = useListCalendarsByUser();
	const filterGoogleCalendars = googleCalendars.filter(calendar => !calendar.id.endsWith('@group.v.calendar.google.com'));
	const syncCalendars = useSyncCalendars(userId, jwtToken);
	const deauthorizeGoogleCalendar = useDeauthorizeGoogleCalendar(userId, jwtToken);
	const [trigger, setTrigger] = useState(false);
	const [dialog, setDialog] = useState(null);
	const [authorized, googleAccount] = useGetAuth(userId, trigger);
	const handleAuthorizeGoogleCalendar = values => {
		const paramState = JSON.stringify({ userId, jwtToken, googleAccount: values.account });
		window.location = `${GOOGLE_CALENDAR_AUTH_URL}&state=${paramState}&login_hint=${values.account}`;
	};
	const handleSync = async values => {
		// console.log(values)
		setProgress({
			text: 'Saving settings. This may take a while.',
			severity: 'info'
		});
		try {
			const data = await syncCalendars(values);
			console.log(data)
			setProgress({
				text: 'Settings saved',
				severity: 'success'
			});
			setTimeout(() => location.reload(), 2000);
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while saving your settings',
				severity: 'error'
			});
		}
		setTimeout(() => setProgress(null), 2000);
	};
	const handleDeauthorize = async () => {
		setDialog(null);
		setProgress({
			text: 'Removing Google Calendar authorization',
			severity: 'info'
		});
		try {
			const data = await deauthorizeGoogleCalendar();
			setProgress({
				text: 'Google Calendar deauthorized',
				severity: 'success'
			});
			setTimeout(() => location.reload(), 2000);
		} catch (err) {
			console.log(err);
			setProgress({
				text: 'An error ocurred while deauthorizing',
				severity: 'error'
			});
		}
		setTimeout(() => {
			setProgress(null);
			setTrigger(prev => !prev);
		}, 2000);
	};
	return (
		<>
			<AccordionDetails>
				<Choose>
					<When condition={authorized}>
						<Button
							color="primary"
							disableFocusRipple
							className={classes.authorizeBtn}
							variant="contained"
							onClick={() => setDialog("alert-deauthorize")}
						>
							Deauthorize {googleAccount}
						</Button>
					</When>
					<Otherwise>
						<AuthorizeGoogleCalendarForm onSubmit={handleAuthorizeGoogleCalendar} id="google-calendar-account" />
					</Otherwise>
				</Choose>
				<If condition={authorized}>
					<Divider className={classes.divider} />
					<Choose>
						<When condition={loading}>
							<Box className={classes.loading}><CircularProgress /></Box>
						</When>
						<Otherwise>
							<SyncForm 
								googleCalendars={filterGoogleCalendars} 
								connectCalendars={connectCalendars} 
								onSubmit={handleSync} 
								userId={userId}
							/>
						</Otherwise>
					</Choose>
				</If>
			</AccordionDetails>
			<FormDialog
				Form={AlertForm}
				id="deauthorize-gmail"
				title="Deauthorize Google Calendar"
				open={dialog === "alert-deauthorize"}
				onCancel={() => setDialog(null)}
				onSubmit={handleDeauthorize}
				dialogText={() => (
					<Typography>
						Are you sure you want to deauthorize the Google Calendar API?
					</Typography>
				)}
				cancelLabel="Return"
				submitLabel="Yes, deauthorize"
			/>
		</>
	);
};

export default GoogleCalendar;