import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { PipelineDrawer } from '../../../../../../components/drawers';

 const DetailsContainer = ({ onSubmit, clearFilter, details, contentId, submitLabel, title, onClose, ...props }) => {

  return (
    <DialogContent>
      <Choose>
        <When condition={details === 'pipeline'}>
          <PipelineDrawer id={contentId} {...props}/>
        </When>
        <Otherwise>
          <DialogContentText>no filter passed.</DialogContentText>
        </Otherwise>
      </Choose>
    </DialogContent>
  );
};

DetailsContainer.defaultProps = {
  submitLabel: 'Submit',
}

export default DetailsContainer;