import React, { useState, useEffect } from 'react';
import Link from "next/link";
import moment from 'moment';
import get from 'lodash/fp/get';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';

import { useEmployees } from '../../../utils/crm/use-employee';
import { usePipelineDetails, useOnUpdatePipeline } from '../../../utils/crm/use-pipeline';
import { formatCurrency, getObjectValue } from '../../../utils/formatting';
import CardTable from '../../../components/card-table/CardTable';
import ActivityCard from './components/activity-card/ActivityCard';
import { PIPELINE_PHASE } from '../../../utils/constants';
import { useDrawer } from '../../../providers/drawers';

const PipelineDrawer = ({ id }) => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [{ pipeline  }] = usePipelineDetails(id, triggerFetch);
  const [{ employees }] = useEmployees();
  pipeline["phase"] = getObjectValue(PIPELINE_PHASE, pipeline?.phase)
  const target = { type: "PIPELINE", id: pipeline?.id,  name: pipeline?.title }
  const creatorName = get('name',find(employees, {'id':get('creatorId', pipeline)}));
  const [openDrawer, closeDrawer] = useDrawer();
  const activity = [
    { type: 'created', createdAt: pipeline?.createdAt, creatorName: creatorName},
  ];

  const sortedActivity = orderBy(activity, ["createdAt"], ["desc", "asc"]);

  const history = get('history', pipeline) || [];

  const combinedHistoryAndActivities = [...(sortedActivity || []), ...history];

  useEffect(() => {
    const updatePipelineSubscription = useOnUpdatePipeline(() => setTriggerFetch(prev => !prev));

    return () => {
      updatePipelineSubscription.unsubscribe();
    }
  }, []);

  return (
    <>
      <CardTable 
        title="Details"
        headers={[
          {id: 'name', label: 'Name'},
          {id: 'contact', label: 'Contact'},
          {id: 'saleAmount', label: 'Sale Amount'},
          {id: 'createdAt', label: 'Date Added'},
          {id: 'assignedTo', label: 'Assigned To'},
          {id: 'closedLostReason', label: "Reason for Closed Lost"}
          // {id: 'tags', label: 'Tags'},
        ]}
        data={{
          title: pipeline?.title,
          contact:
            <Link 
              href={{ 
                  pathname: '/management/pipeline', 
                  query: {id: pipeline.contact?.id, type: 'contact', action: 'view'} 
              }}
              as={`/customers/contact/${pipeline.contact?.id}`}
            >
              <Button
                variant="contained"
                color="neutral1"
                disableElevation
                style={{ margin: '0px 0px', minWidth: 'unset', textAlign: 'left',}}
                onClick={(event) => {
                  event.stopPropagation();
                  closeDrawer();
                }}
              >
                {pipeline.contact?.full_name}
              </Button>
            </Link>,
          saleAmount: `$${formatCurrency(pipeline?.sale_amount)}`,
          createdAt: moment(pipeline?.createdAt).format('MM/DD/yyyy'),
          assignedTo: pipeline?.assignedTo?.name,
          closedLostReason: pipeline?.closedLostReason
        }}
        chip={
          <Chip
            label={pipeline?.phase?.label}
            style={{
              borderRadius: 4,
              backgroundColor: pipeline?.phase?.color,
              marginLeft: 10,
              display: 'inline-flex',
              alignSelf: 'center',
            }}
          />
        }
      />
    <Box mt={2} /> 
    <Card elevation={0}>
      {/* <Timeline align="left"> */}
        <CardHeader
          title="Activity"
          style={{
            backgroundColor: "#F3F4F6",
            borderRadius: "4px",
            marginBottom: "24px",
            marginTop: "24px",
            py: 1
          }}
          titleTypographyProps={{
            variant: 'subtitle2'
          }}
        />
        {/* <For each="content" index="index" of={sortedActivity}> */}
          {/* <CreatedTimelineItem content={content} isLast={true} /> */}
          <ActivityCard
            activity={combinedHistoryAndActivities}
            target={target}
          />
        {/* </For> */}
      {/* </Timeline> */}
    </Card>
    </>
  )
}

export default PipelineDrawer;