import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import get from 'lodash/fp/get';
import flow from 'lodash/flow';
import flatMap from 'lodash/fp/flatMap';
import { Auth } from "aws-amplify";

import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useDialog } from '../../../../../providers/dialogs/DialogProvider';
import SnackProgress from '../../../../shared/snack-progress/SnackProgress';
import {
  usePipelineDetails,
  useUpdatePipeline,
  useOnUpdatePipeline,
  useArchivePipeline,
} from '../../../../../utils/crm/use-pipeline';
import { PIPELINE_PHASE } from '../../../../../utils/constants';
import { getObjectValue } from '../../../../../utils/formatting';
import FormDialog from '../../../../shared/form-dialog/FormDialog';
import AlertForm from '../../../../shared/alert-form/AlertForm';
import { useGetEmployee, useUpdateEmployee } from '../../../../../utils/crm/use-employee';
import { formatCurrency } from '../../../../../utils/formatting';
import { useDrawer } from '../../../../../providers/drawers';

const Actions = ({ id }) => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [{ pipeline }] = usePipelineDetails(id, triggerFetch)
  const [openDialog, closeDialog] = useDialog();
  const updatePipeline = useUpdatePipeline();
  const [closedWon, setClosedWon] = useState(false);
  const [progress, setProgress] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const archivePipeline = useArchivePipeline();
  const [dialog, setDialog] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [{ employee }] = useGetEmployee(Auth.user.username);
  const updateEmployee = useUpdateEmployee();
  const [openDrawer, closeDrawer] = useDrawer();

  const handleArchivePipeline = async (values) => {
    setDisableButton(true);
    try {
      const data = await archivePipeline(values);
      closeDrawer();
      setDialog(null);
      setTriggerFetch(prev => !prev);
    } catch (e) {
      console.log(e)
    }
  };

  const addToReport = async (pipeline) => {
    const contact = get('contact', pipeline);
    const company = get('company.name', contact) ? get('company.name', contact) : null;
    const title = get('project_title', pipeline);
    const sale_amount = `$${formatCurrency(get('sale_amount', pipeline))}`;


    const values = [contact.full_name, company, title, sale_amount].filter(Boolean).join(" - ");
    const formValues = get('formFields', JSON.parse(employee.reportFormData));
    const updatedValues = {...formValues, quotesAndLeads: formValues.quotesAndLeads.concat("\n", values)}
    
    if (contact) {
      setProgress({
        text: "Adding to Report...",
        severity: "info"
      });
      try {
        await updateEmployee(updatedValues, Auth.user.username);
        setProgress({
          text: 'Added!',
          severity: 'success'
        });
        setTimeout(() => setProgress(null), 2000);
      } catch (e) {
        console.log(e)
        setProgress({
          text: 'Error on adding to report',
          severity: 'error'
        });
      }
    };
  };

  const handleEdit = async (values, form) => {
    const saleAmtType = (amount) => {
      if (typeof(amount) !== 'number') {
        return parseInt(values.sale_amount?.replace('$', '').replace(',', ''))
      } else return amount
    }
    try {
      setProgress({
        text: 'Updating Status...',
        severity: 'info'
      });
      closeDialog();
      const data = await updatePipeline({...values, sale_amount: saleAmtType(values.sale_amount)}, pipeline);
      setTriggerFetch(prev => !prev);
      setProgress({
				text: 'Successfully Updated!',
				severity: 'success'
			});
      setTimeout(() => {
				setProgress(null);
				form.restart();
			}, 2000);
    }catch(e) {
      console.log('error:', e)
      setProgress({
        text: 'Error when updating',
        severity: 'error'
      });
    };
  };

  const onOpenDialog = (action) => {
    switch(action){
      case('edit'):{
        openDialog({
          variant: 'form',
          form: 'pipeline',
          id: 'update-pipeline',
          submitLabel: 'Update',
          title: 'Update Pipeline',
          initialValues: {
            ...pipeline,
            phase: getObjectValue(PIPELINE_PHASE, pipeline.phase),
          },
          onSubmit: handleEdit
        });
        break;
      }
      default: {
        console.log('no action passed.')
      }
    }
  }

  useEffect(() => {
    const updatePipelineSubscription = useOnUpdatePipeline(() => setTriggerFetch(prev => !prev));

    return () => {
      updatePipelineSubscription.unsubscribe();
    }
  }, []);

  return(
    <>
      <Button 
        variant="contained"
        size="small"
        color="neutral1"
        disableElevation
        onClick={() => onOpenDialog('edit')}
        style={{
          marginRight: 10,
          color: 'black',
          maxHeight: 35
        }}>
          Edit
        </Button>
        <IconButton
          color="neutral1"
          variant="contained"
          style={{
            maxHeight: 35,
            backgroundColor: '#F2F2F2',
            marginRight: 10,
          }}
          edge="end"
          size="small"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
            <MoreVertIcon sx={{color: 'black'}} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={1}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => addToReport(pipeline)}>Add to Report</MenuItem>
          <MenuItem onClick={() => setDialog("archive-pipeline-customer")}>Archive</MenuItem>
        </Menu>
        <FormDialog
          Form={AlertForm}
          id="archive-pipeline"
          title={`Archive Contact from Pipeline`}
          maxWidth="md"
          initialValues={{selected: [pipeline.id]}}
          open={ dialog === "archive-pipeline-customer" }
          onCancel={() => setDialog(null)}
          onSubmit={handleArchivePipeline}
          disableButton={disableButton}
          submitLabel={`Archive`}
        />
        <If condition={progress}>
          <SnackProgress progress={progress} />
        </If>
    </>
  )
}

export default Actions;