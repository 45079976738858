import React from 'react';
import clsx from 'clsx';
import { Auth } from "aws-amplify";
import { useRouter } from 'next/router';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import includes from 'lodash/includes';
import { KeyboardArrowDown, Settings as SettingsIcon } from '@material-ui/icons';

import { Settings, Notifications, Account } from './components';
import { useUserLogout } from '../../../../../../utils/use-user';
import { useNotifications } from '../../../../../../utils/crm/use-notification';
import { useUserAttributes, useUserGroups, getInitials } from '../../../../../../utils/use-user';
import { Box, colors, ListItemIcon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    userText: {
        fontWeight: 500,
        color: '#FFF !important'
    },
    userContainer: {
      width: 'unset'
    },
    userButton: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      alignItems: 'start'
    },
    rounded: {
      color: theme.palette.primary.dark,
      textTransform: 'uppercase',
      backgroundColor: '#FFF',
      marginLeft: theme.spacing(2)
    },
  }));


const UserMenu = ({ className, user}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState(null);
    const anchorRef = React.useRef(null);
    const router = useRouter();
    const logoutUser = useUserLogout();
    const [seen, setSeen] = React.useState(false);
    const notifications = useNotifications();
    const groups = useUserGroups();
    const attributes = useUserAttributes();
    const name = attributes.name ? attributes.name : attributes.email;
  
    const initials = getInitials(name);
    React.useEffect(() => setSeen(false), [notifications]);
    
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleLogout = async () => {
      console.log('Logged out!');
      Auth.signOut();
      await router.push('/');
      localStorage.removeItem("persist:root")
      // localStorage.clear();
      logoutUser();
    }
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
  
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);

    return (
      <>
        <ButtonBase
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Grid container style={{ width: 'unset', alignItems: 'center'}}>
            <Grid item>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: 8,
                  alignItems: 'end' 
                }}
              >
                <Typography variant="subtitle2" style={{color: '#111827' /*neutral 900 */}}>{name}</Typography> 
                <Typography variant='caption' style={{color: '#374151' /*neutral 700 */}}>
                  <Choose>
                    <When condition={includes(groups, 'manager')}>
                      System Administrator
                    </When>
                    <When condition={includes(groups, 'admin')}>
                      Administrator
                    </When>
                    <When condition={includes(groups, 'editor')}>
                      Editor
                    </When>
                    <When condition={includes(groups, 'sales')}>
                      Sales Representative
                    </When>
                    <Otherwise>
                      User
                    </Otherwise>
                  </Choose>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Avatar variant='squared' style={{ backgroundColor: colors.blue[900], borderRadius: 2}}>
                  {initials}
                </Avatar>
                <KeyboardArrowDown fontSize='small' color='action' style={{margin: 'auto'}} />
              </Box>
            </Grid>
          </Grid>
        </ButtonBase>
        {/* </Tooltip> */}
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', right: 0 }}
            >
              <Paper style={{minWidth: 160}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem onClick={() => setDialog('account')}>
                      My account
                    </MenuItem>
                    <MenuItem onClick={() => { setDialog('notifications'); setSeen(true) }}>
                      <Badge color="error" variant="dot" invisible={seen || notifications.length === 0}>
                        Notifications
                      </Badge>
                    </MenuItem>
                    <MenuItem onClick={() => setDialog('settings')}>Settings</MenuItem>
                    <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Settings open={dialog === 'settings'} onCancel={() => setDialog(null)} />
        <Notifications open={dialog === 'notifications'} onCancel={() => setDialog(null)} data={notifications}/>
        <Account open={dialog === 'account'} onCancel={() => setDialog(null)} />
      </>
    );
};

export default UserMenu;