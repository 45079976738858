import merge from "lodash/merge";
import unset from "lodash/unset";
import { handleActions } from "redux-actions";
import { normalize } from "normalizr";

import * as actions from '../actions/cms';
import {
  Page,
  Collection,
  Product,
  Asset,
  Home,
  Contact,
  AboutUs,
  TradeProgram
} from "../schemas/cms";

import { normalizeEntries, normalizeAssets } from "../../utils/normalize-data";


export default handleActions(
  {
    [actions.getPages]: {
      SUCCESS: (state, action) => {
        return state;
      },
    },
    [actions.getPage]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Page);
        return merge({}, state, entities);
      },
    },
    [actions.getEntries]: {
      SUCCESS: (state, action) => {
        const { entities } = normalizeEntries(action.payload.items);
        return merge({}, state, entities);
      },
    },
    [actions.getAssets]: {
      SUCCESS: (state, action) => {
        const { entities } = normalizeAssets(action.payload.items);
        // console.log(entities);
        return merge({}, state, entities);
      },
    },
    [actions.deleteAsset]: {
      SUCCESS: (state, action) => {
        delete state.assets[action.payload];
        return state;
      },
    },
    [actions.createCollection]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Collection);
        return merge({}, state, entities);
      },
    },
    [actions.addCollectionPage]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Page);
        return merge({}, state, entities);
      },
    },
    [actions.createProduct]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Product);
        return merge({}, state, entities);
      },
    },
    [actions.getProduct]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Product);
        return merge({}, state, entities);
      },
    },
    [actions.updateProduct]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, Product);
        state.products[id] = entities.products[id];
        return merge({}, entities, state);
      },
    },
    [actions.getCollection]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Collection);
        return merge({}, state, entities);
      },
    },
    [actions.updateCollection]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Collection);
        // return merge({}, state, entities);

        Object.assign(state.collections, entities.collections);
        return merge({}, state);
      },
    },
    [actions.deleteCollection]: {
      SUCCESS: (state, action) => {
        delete state.collections[action.payload];
        return state;
      },
    },
    [actions.removeCollectionPage]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, Page);
        // return merge({}, state, entities);
        state.pages[id] = entities.pages[id];
        return merge({}, entities, state);
      },
    },
    [actions.getCollectionsPage]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, Page);
        // return merge({}, state, entities);
        state.pages[id] = entities.pages[id];
        return merge({}, entities, state);
      },
    },
    [actions.createAsset]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Asset);
        return merge({}, state, entities);
      },
    },
    [actions.updateAsset]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, Asset);
        state.assets[id] = entities.assets[id];
        return merge({}, entities, state);
      },
    },
    [actions.updateHome]: {
      SUCCESS: (state, action) => {
        const { entities } = normalize(action.payload, Home);
        Object.assign(state.home, entities.home);
        return merge({}, state);
      },
    },
    [actions.updateContactPage]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, Contact);
        state.contact[id] = entities.contact[id];
        return merge({}, entities, state);
      },
    },
    [actions.updateAboutUs]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, AboutUs);
        state.aboutUs[id] = entities.aboutUs[id];
        return merge({}, state);
      },
    },
    [actions.updateTradeProgramPage]: {
      SUCCESS: (state, action) => {
        const id = action.payload.sys.id;
        const { entities } = normalize(action.payload, TradeProgram);
        state.tradeProgram[id] = entities.tradeProgram[id];
        return merge({}, entities, state);
      },
    },
    // [actions.logoutUser]: () => {
    //   return undefined;
    // },
  },
  {}
);