import React, { useState } from 'react';
import clsx from 'clsx';
import { Auth } from "aws-amplify";
import { 
  AppBar, 
  Toolbar, 
  Badge, 
  Hidden, 
  IconButton,
  Chip,
  Typography
} from '@material-ui/core';
import FlexDivider from '../../../shared/flex-divider';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useUserGroups } from '../../../../utils/use-user';
import useWindowDimensions from '../../../../utils/use-window';
import { CURRENT_ENV } from '../../../../utils/constants';
import includes from 'lodash/includes';

import { UserMenu, Schedule } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0]
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  }
}));

const Topbar = ({ onSidebarOpen, ...rest}) => {
  const classes = useStyles();
  const groups = useUserGroups();
  const { height, width } = useWindowDimensions();
  const isManager = includes(groups, 'manager');
  const isAdmin = includes(groups, 'admin');
  const isEditor = includes(groups, 'editor');

  return(
    <AppBar
      {...rest}
      className={classes.root}
      style={{
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
    >
      <Toolbar 
        disableGutters
        style={{
          minHeight: 64,
          left: 0,
          paddingLeft: 2,
          paddingRight: 2
        }}
      >
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon fontSize="small" color="action"/>
          </IconButton>
          <Typography color="primary">Panafold Connect</Typography>
        </Hidden>
        { /* Connect Logo */ }
        <If condition={CURRENT_ENV != 'production'}>
          <Chip label={`env: ${CURRENT_ENV}`} />
          <Typography color="primary" variant='subtitle2' style={{ paddingLeft: 8}}>
            width: {width} {' '}
            <Choose>
              <When condition={width < 600}>
                xs
              </When>
              <When condition={width < 900}>
                sm
              </When>
              <When condition={width < 1200}>
                md
              </When>
              <When condition={width < 1536}>
                lg
              </When>
              <When condition={width >= 1536}>
                xl
              </When>
              <Otherwise>
                unknown
              </Otherwise>
            </Choose>
          </Typography>
        </If>
        <FlexDivider />
        <If condition={isManager || isAdmin || isEditor}>
          <Schedule />
        </If>
        <UserMenu className={classes.signOutButton} user={Auth.user.attributes?.email}/>
      </Toolbar>
    </AppBar>
  )
};

export default Topbar;