import React from "react";
import styled from "styled-components";
import Link from "next/link";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Typography } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';

import { VERSIONS } from '../../../../utils/changelog.js';

const ImageContainer = styled.img`
  // width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: contain;
`;

const useStyles = makeStyles(theme => ({
  title: {
    '& .MuiTypography-h6': {
      fontSize: '16px',
      fontWeight: 600
    },
    textAlign: 'center'
  },
  content: {
    fontSize: '14px',
    // maxWidth: 350
  },
  button: {
    margin: theme.spacing(1),
  }
}));


const LogoutDialog = ({ open, onSubmit, newVersion, forceLogout, setOpenDialog }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      
      <ImageContainer src={"/static/images/undraw_update_uxn2.png"} />
      <DialogTitle className={classes.title}>
        New Version {newVersion}!
      </DialogTitle>
      <Choose>
        <When condition={forceLogout}>
          <DialogContent className={classes.content}>
            <List dense={true}>
              <For each="change" of={VERSIONS[0].changes}>
                <ListItem>- {change}</ListItem>
              </For>
            </List>
          
          </DialogContent>
          <DialogActions>
          <Typography variant="subtitle2">Please logout to experience all the new changes!</Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              width={136}
              onClick={onSubmit}
            >
              Log Out
            </Button>
          </DialogActions>
        </When>
        <Otherwise>
          <DialogContent className={classes.content}>
            <List dense={true}>
              <For each="change" of={VERSIONS[0].changes}>
                <ListItem>- {change}</ListItem>
              </For>
            </List>
          </DialogContent>
          <DialogActions>
            <Link href={`/changelog`}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => setOpenDialog(null)}
              >
                See All Changes
              </Button>
            </Link>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              // width={136}
              onClick={() => setOpenDialog(null)}
            >
              Dismiss
            </Button>
          </DialogActions>
        </Otherwise>
      </Choose>
    </Dialog>
  );
};

export default LogoutDialog;