export const SALEROUTES = [
  '/customers',
  '/website/form-submissions'
];

export const EDITORROUTES = [
  '/website/pages'
]

export const ADMINROUTES = [
  '/reports/export-center'
];