import React, { createContext, useState } from 'react';

import { DrawerContainer } from './components';

const DrawerContext = createContext();

export const useDrawer = () => React.useContext(DrawerContext);

const DrawerProvider = ({ children }) => {
  const [drawers, setDrawers] = useState([]);

  const createDrawer = (option) => {
    const drawer = { ...option, open: true };
    setDrawers((drawers) => [...drawers, drawer]);
  };
  
  const closeDrawer = () => {
    setDrawers((drawers) => {
      const latestDrawer = drawers.pop();
      if(!latestDrawer) return drawers;
      if(latestDrawer.onClose) latestDrawer.onClose();
      return [...drawers].concat({ ...latestDrawer, open: false});
    })
  };

  const contextValue = React.useRef([createDrawer, closeDrawer]);

  return(
    <DrawerContext.Provider value={contextValue.current}>
      {children}
      {drawers.map((drawer, i) => {
        const { onClose, ...drawerParams } = drawer;
        const handleKill = () => {
          if(drawer.onExited) drawer.onExited();
          setDrawers((drawers) => drawers.slice(0, drawers.length - 1));
        };

        return(
          <DrawerContainer
            key={i}
            onClose={closeDrawer}
            onKill={handleKill}
            {...drawerParams}
          />
        )
      })}
    </DrawerContext.Provider>
  );
}

export default DrawerProvider;