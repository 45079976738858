import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button as MuiButton
} from '@material-ui/core';
import styled from 'styled-components';
import Link from 'next/link';
import Logo from '../../../logo';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from "next/router";

import { PrivacyPolicy, TermsOfService } from './components';
import { Changes } from '../../../sidebar-page/components/Topbar/components/Changelog/components';

import { VERSIONS } from '../../../../utils/changelog';

const CURRENT_VERSION = VERSIONS[0].version;

const Button = styled(MuiButton)`
  // color: #FFF !important;
  margin-right: 35px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // flex: '1 1 auto !important',
    height: '100vh',
    width: '100%'
    // border: '1px solid green'
  },
  container: {
    flex: '1 1 auto',
    flexDirection: 'row-reverse',
  },
  item: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  logoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(2)
  },
  logo: {
    height: 42,
    width: 42,
  },
  contentContainer: {
    flex: '1 1 auto',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 500,
    padding: theme.spacing(3),
    paddingTop: '100px',
    width: '100%',
  },
  imageContainer: {
    alignItems: 'center',
    backgroundImage: 'url(/static/images/flexform_la_crop.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
    },
  },
  links: {
    maxWidth: '500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 75,
    textAlign: 'center',
    justifyContent: 'center'
  }
}));

const AuthContainer = ({ children, authState, onStateChange, authData }) => {
  const classes = useStyles();
  const router = useRouter();
  const { query } = router;
  const [dialog, updateDialog] = React.useState(null);

  return(
    <If condition={authState !== "signUp" && authState !== "signedIn"}>
      <Box  component="main" className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} lg={4} className={classes.item}>
          <Box className={classes.logoContainer}>
            <Link href="/" passHref>
              <a>
                <Logo className={classes.logo} />
              </a>
            </Link>
          </Box>
          <Box className={classes.contentContainer}>
            <Box className={classes.content}>
              {
                React.Children.map(children, child =>
                  React.cloneElement(child, { onStateChange, authState, authData }))
              }
            </Box>
          </Box>
          {/* <Box display="flex" flex="1" /> */}
          <Grid container className={classes.links}>
            <Link href={{ pathname: '/', query: { dialog: 'privacy-policy' }}}>
              <Button>Privacy Policy</Button>
            </Link>
            <Link href={{ pathname: '/', query: { dialog: 'terms-of-service' }}}>
              <Button>Terms of Service</Button>
            </Link>
            <Button onClick={() => onStateChange("signUp", {})} target="_blank">
              About Connect
            </Button>
            {/* <Button component="a" href="https://www.panafold.co/about" target="_blank">
              Our Team
            </Button> */}
            <Typography style={{ padding: '6px 8px', fontSize: '0.8125 rem', fontWeight: '500', lineHeight: '1.75'}}>
              v.{CURRENT_VERSION}
            </Typography>
            <PrivacyPolicy open={query.dialog === 'privacy-policy'} onClose={() => router.push("/")} />
            <TermsOfService open={query.dialog === 'terms-of-service'} onClose={() => router.push("/")} />
            <Changes open={dialog === 'change-log'} onCancel={() => updateDialog(null)} />
          </Grid> 
        </Grid>
        <Grid item xs={12} lg={8} className={classes.imageContainer}>
        </Grid>
      </Grid>
    </Box>
    </If>
  )
}

export default AuthContainer;