import React, {useState} from "react";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MapIcon from '@material-ui/icons/Map';
import { makeStyles } from '@material-ui/core/styles';

import FormDialog from '../../../../../shared/form-dialog';
import AlertForm from '../../../../../shared/alert-form';

import { Board } from './components';

const useStyles = makeStyles(theme => ({
    icon: {
        color: '#FFF'
    }
}));

const Roadmap = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
    <div>
        <Tooltip title="Roadmap">
            <IconButton aria-label="roadmap" onClick={() => setOpen(true)}>
                <MapIcon color="inherit" className={classes.icon}/>
            </IconButton>
        </Tooltip>
        <Board open={open} onCancel={() => setOpen(false)}/>
    </div>
    )
};

export default Roadmap;
