import React, { useState, useEffect } from 'react';

import { default as actions } from '../../redux/actions/amplifyActions';
import { Auth, API, graphqlOperation } from "aws-amplify";
import { default as useActions } from '../use-actions';
import { orderBy, isEmpty, intersectionBy, uniqBy } from 'lodash';
import get from 'lodash/fp/get';
import * as actionsCRM from '../../redux/actions/crm';
import { onCreatePipeline, onUpdatePipeline, onDeletePipeline } from '../../src';
import { useUpdateEmployee, useGetEmployee } from './use-employee';
import { formatCurrency } from '../formatting';

export const useCreatePipeline = () => {
  const { createPipeline, createNote } = useActions(actions);
  const updateEmployee = useUpdateEmployee();
  const [{ employee }] = useGetEmployee(Auth.user.username);

  return async values => {
    console.log('create values', values)
    const newPipeline = await createPipeline({
      input: {
        type: "Pipeline",
        phase: values.phase?.value,
        sale_amount: values.sale_amount,
        project_title: values.project_title,
        pipelineContactId: values.contact.id,
        description: values.description,
        pipelineAssignedToId: get('assignedTo.id', values),
        employeeId: get('assignedTo.id', values),
        contactId: values.contact.id,
        searchName: values.contact?.full_name.toLowerCase(),
        closedLostReason: values.closedLostReason?.label,
        sendNotification: values.sendNotification,
        archived: false
      }
    });
    if (values.phase?.value == "CLOSEDLOST" && values.closedLostReason) {
      const logClosedLost = async ({ message, noteContactId, owner }) => {
        const res = await createNote({
          input: {
            message: message,
            noteContactId: noteContactId,
            owner: owner
          }
        });
        console.log('res', res)
      };
      const messageText = "Pipeline Project: " + values.project_title + " Removed - " + (values.closedLostReason ? values.closedLostReason.label : "" )
      const res = logClosedLost({
        message: messageText,
        noteContactId: values.contactId,
        owner: newPipeline.value.assignedTo.email
      });
    };
    if (values.addToReport) {
      const contact = get('contact', values);
      const company = get('company', contact) ? get('company', contact) : null;
      const title = get('project_title', values);
      const sale_amount = `$${formatCurrency(get('sale_amount', values))}`;

      const reportValues = [contact.full_name, company, title, sale_amount].filter(Boolean).join(" - ");
      const currReportValues = get('formFields', JSON.parse(employee.reportFormData));
      const updatedValues = {...currReportValues, quotesAndLeads: currReportValues.quotesAndLeads.concat("\n", reportValues)}

      if (contact) {
        try {
          await updateEmployee(updatedValues, Auth.user.username);
        } catch (e) {
          console.log(e)
        }
      };
    }
    console.log('newPipeline', newPipeline)
    return newPipeline;
  }
};

export const useUpdatePipeline = () => {
  const { updatePipeline, createNote } = useActions(actions);
  const archivePipeline = useArchivePipeline();

  return async (values, prevPipeline) => {
    console.log('update values', values)
    let history = [];
    if (values.history) {
      if (prevPipeline.history) {
        history = prevPipeline.history.concat(values.history)
      } else {
        history = values.history
      }
    } else {
      history = prevPipeline.history
    }
    const updatedPipeline = await updatePipeline({ input: {
      type: "Pipeline",
      id: values.id,
      phase: values.phase?.value,
      sale_amount: values.sale_amount,
      project_title: values.project_title,
      pipelineContactId: values.contact.id,
      pipelineAssignedToId: get('assignedTo.id', values),
      employeeId: get('assignedTo.id', values),
      contactId: values.contact.id,
      closedLostReason: values.closedLostReason?.label,
      sendNotification: values.sendNotification,
      history: !isEmpty(history) ? history : null,
      archived: false
    }});
    if (values.phase?.value === "CLOSEDLOST" && values.closedLostReason) {
      const logClosedLost = async ({ message, noteContactId, owner }) => {
        const res = await createNote({
          input: {
            message: message,
            noteContactId: noteContactId,
            owner: owner
          }
        });
        console.log('res', res)
      };
      
      const messageText = "Pipeline Project: " + values.project_title + " Removed - " + (values.closedLostReason ? values.closedLostReason.label : "" )
      const res = logClosedLost({
        message: messageText,
        noteContactId: values.contactId,
        owner: updatedPipeline.value.assignedTo.email
      });
    }
    if (values.phase?.value === ("CLOSEDLOST" || "CLOSEDWON")) {
      try {
        await archivePipeline({ selected: [values.id] });
      } catch (e) {
        console.log(e)
      }
    }
    console.log('updatedPipeline', updatedPipeline)
    return updatedPipeline;
  };
};

export const useArchivePipeline = () => {
  const { updatePipeline, createActivity } = useActions(actions);
  return async ({ selected }) => {
    selected.map( async (pipelineId) => {
      const data = await updatePipeline({ input: { id: pipelineId, archived: true }})
      const log = logArchivePipeline({
        pipelineId: data.value.id,
        contactName: data.value.contact?.full_name,
        contactId: data.value.contact?.id,
        project_title: data.value.project_title
      })
      console.log('data', data)

    })

    const logArchivePipeline = async({ pipelineId, contactId, project_title }) => {
      const res = await createActivity({
        input: {
          activityId: contactId,
          type: 'ACTIVITY',
          creatorId: Auth.user.attributes.sub,
          activityCreatorId: Auth.user.attributes.sub,
          action: 'UPDATED',
          activityType: 'PIPELINE',
          payload: {
            name: pipelineId,
            targetId: contactId,
            subject: project_title
          }
        }
      })
    }
  }
};

export const useDeletePipeline = () => {
  const { deletePipeline, createActivity } = useActions(actions);
  return async ({ selected }) => {
    selected.map( async (pipelineId) => {
      const data = await deletePipeline({ input: { id: pipelineId }})
      const log = logDeletePipeline({
        pipelineId: data.value.id,
        contactName: data.value.contact?.full_name,
        contactId: data.value.contact?.id,
        project_title: data.value.project_title
      });
    })

    const logDeletePipeline = async({pipelineId, contactId, project_title}) => {      
      const res = await createActivity({
        input: {
          activityId: contactId,
          type: 'ACTIVITY',
          creatorId: Auth.user.attributes.sub,
          activityCreatorId: Auth.user.attributes.sub,
          action: 'DELETED',
          activityType: 'PIPELINE',
          payload: {
            name: pipelineId,
            targetId: contactId,
            subject: project_title
          }
        }
      })
    }
  }
};

const useSortBy = () => {
  const { 
    getPipelinesByUpdatedAt,
    getPipelinesByCreatedAt,
    getPipelinesBySearchName,
    getPipelinesByPhase
  } = useActions(actions);

  return async (action, token) => {
    const { type, direction } = action.sortBy;
    try {
      if (type === "updatedAt") {
        const data = await getPipelinesByUpdatedAt({
          filter: {archived: { eq: false }},
          limit: 300,
          nextToken: token,
          sortDirection: direction,
          type: "Pipeline"
        });
        return {
          result: data.value.listPipelinesByUpdatedAt.items,
          nextToken: data.value.listPipelinesByUpdatedAt.nextToken,
        };
      } 
      else if (type === "createdAt") {
        const data = await getPipelinesByCreatedAt({
          filter: {archived: { eq: false }},
          limit: 300,
          nextToken: token,
          sortDirection: direction,
          type: "Pipeline"
        });
        return {
          result: data.value.listPipelinesByCreatedAt.items,
          nextToken: data.value.listPipelinesByCreatedAt.nextToken,
        };
      }
      else if (type === "searchName") {
        const data = await getPipelinesBySearchName({
          filter: {archived: { eq: false }},
          limit: 300,
          nextToken: token,
          sortDirection: direction,
          type: "Pipeline"
        });
        return {
          result: data.value.listPipelinesBySearchName.items,
          nextToken: data.value.listPipelinesBySearchName.nextToken,
        };
      }
      else if (type === "phase") {
        const data  = await getPipelinesByPhase({
          filter: {archived: { eq: false }},
          limit: 300,
          nextToken: token,
          sortDirection: direction,
          type: "Pipeline"
        });
        return {
          result: data.value.listPipelinesByPhase.items,
          nextToken: data.value.listPipelinesByPhase.nextToken
        };
      }
    } catch (e) {
      console.log('error:', e)
      return {
				result: [],
				nextToken: null,
			}
    }
  }
};

export const useFilterBy = () => {
  const { getPipelines, getPipelinesByEmployee, getPipelinesByPhase } = useActions(actions);
  return async (action) => {
    const {
      search,
      assignedTo,
      createdAt_start,
			createdAt_end,
      unassigned,
      phase,
      archived
    } = action.filterBy
    try {
      let list = [];
      const direction = action.sortBy.direction.toLowerCase();

      // Filter Data Prep
      const mainFilter = () => {
        const filter = {};
        filter.archived = { eq: archived ? true : false }
        if (search) filter.searchName = { contains: search };
        if (createdAt_start && createdAt_end) filter.createdAt = { ge: createdAt_start, le: createdAt_end };
        return filter;
      };
      const pipelineFilter = mainFilter();

      if (!isEmpty(pipelineFilter)) {
        let total = null;
        let token = null;
        let rows = [];
        while(true) {
          const data = await getPipelines({
            limit: 500,
            nextToken: token,
            filter: pipelineFilter
          });
          token = data.value.listPipelines.nextToken;
          rows = rows.concat(data.value.listPipelines.items);
          total = rows.length
          if (token === null) break;
        }
        if (rows.length) {
          list = list.concat([rows]);
        }
      };

      if (assignedTo.length > 0) {
				let result = []
				await Promise.all(assignedTo.map(async employee => {
					let token = null;
          let total = null;
					while(true){
						const data = await getPipelinesByEmployee({ 
              employeeId: {eq: employee.id},
              limit: 500,
              nextToken: token,
              type: "Pipeline",
              sortDirection: "DESC",
              filter: { archived: { eq: archived ? true : false } }
						});
            token = data.value.listPipelinesByEmployee.nextToken
            result = result.concat(data.value.listPipelinesByEmployee.items)
            total = result.length
						if(token === null) break;
					}
				}))
				const pipelines = uniqBy(result, 'id');
				list = list.concat([pipelines]);
			};


      if (phase?.length > 0) {
				let result = []
				await Promise.all(phase?.map(async item => {
					let token = null;
          let total = null;
					while(true){
						const data = await getPipelinesByPhase({ 
              phase: { eq: item.value },
              limit: 500,
              nextToken: token,
              type: "Pipeline",
              sortDirection: "DESC",
              filter: { archived: { eq: archived ? true : false } }
						});
            token = data.value.listPipelinesByPhase.nextToken
            result = result.concat(data.value.listPipelinesByPhase.items)
            total = result.length
						if(token === null) break;
					}
				}))
				const pipelines = uniqBy(result, 'id');
				list = list.concat([pipelines]);
			};

      list = intersectionBy(...list, 'id');
			return {
				result: orderBy(list, [action.sortBy.type], [direction]),
				total: list.length
			}
    } catch (e) {
      console.log('error:', e)
      return [];
    }
  };
};

export const usePipeline = (action, token, limit, triggerFetch) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [total, setTotal] = useState(null);
  const _sortBy = useSortBy();
  const _filterBy = useFilterBy();
  const fetchPipelines = async () => {
    setIsLoading(true);
    if (action.filterBy == null) {
      try {
        const { result, nextToken } = await _sortBy(action, token, limit);
        if (action.nextPage) {
          setPipelines(prev => prev.concat(result));
        } else {
          setPipelines(result);
        }
        setNextToken(nextToken);
        setTotal(null);
      } catch (e) {
        console.log('error:', e)
      }
    } else {
      try {
        const { result, total } = await _filterBy(action);
        setPipelines(result);
        setTotal(total);
      } catch (e) {
        console.log('error:', e)
      }
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    fetchPipelines()
  }, [action, limit, triggerFetch]);
  return [{pipelines, nextToken, isLoading, total}];
};


export const useGetPipeline = (contactId, triggerFetch) => {
  const [isPipelinesLoading, setIsPipelinesLoading] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const { listPipelinesByContact } = useActions(actions);

  const fetchPipeline = async () => {
    setIsPipelinesLoading(true);
    try {
      const data = await listPipelinesByContact({
        limit: 1000,
        contactId: { eq: contactId },
        type: "Pipeline",
        filter: { archived: { eq: false } }
      });
      setPipelines(data.value.items);
    } catch(e) {
      console.log('e', e);
      setPipelines(null);
    }
    setIsPipelinesLoading(false);
  }
  useEffect(() => { if (contactId) fetchPipeline()}, [contactId, triggerFetch]);
  return [{ pipelines, isPipelinesLoading }];
};

export const usePipelineDetails = (id, triggerFetch) => {
  const [pipeline, setPipeline] = useState({});
  const { getPipelineDetails } = useActions(actions);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async() => {
    setIsLoading(true);
    if(id){
      try {
        const _pipeline = await getPipelineDetails({ id });
        setPipeline({..._pipeline.value.getPipeline});
      } catch(e) {
        console.log(e);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => { fetchData() }, [id, triggerFetch]);

  return [{ pipeline, isLoading }];
}

export const useFetchTotalPipelines = (clearTable) => {
  const { getPipelines } = useActions(actions);
  const [total, setTotal] = useState(0);
  const fetchTotal = async () => {
    try {
      let token = null;
      let result = 0;
      while (true) {
        const data = await getPipelines({
          limit: 500,
          nextToken: token,
          filter: { archived: { eq: false } }
        });
        token = data.value.listPipelines.nextToken;
        result += data.value.listPipelines.items.length;
        if(token === null) break;
      }
      setTotal(result);
    } catch(e){
      console.log('error:', e)
      setTotal(0);
    }
  };
  useEffect(() => { fetchTotal() }, [clearTable]);
  return [{total}];
};

export const useAssignPipeline = () => {
  const { updatePipeline } = useActions(actions);

	return async values => {
		const updatedPipelines = await Promise.all(values.selected.map(pipelineId => {
			return updatePipeline({ input: {
				id: pipelineId,
				pipelineAssignedToId: get('id', values.assignedTo),
        employeeId: get('id', values.assignedTo)
			}});
		}));
		return updatedPipelines; 
	}
};

export const useUpdateFilterPipelines = () => {
  const updateFilterPipelines = useActions(actionsCRM.updateFilterPipelines);
  return values => {
    return updateFilterPipelines(values);
  }
};

export const useResetOneFilterPipelines = () => {
  const resetOneFilterPipelines = useActions(actionsCRM.resetOneFilterPipelines);
  return value => {
    return resetOneFilterPipelines(value);
  };
};

export const useResetAllFilterPipelines = () => {
  const resetAllFilterPipelines = useActions(actionsCRM.resetAllFilterPipelines);
  return value => {
    return resetAllFilterPipelines(value);
  }
};

export const useOnCreatePipeline = callback => {
  return API.graphql(graphqlOperation(onCreatePipeline)).subscribe({
      next: callback,
      error: error => console.log(error)
  });
};

export const useOnUpdatePipeline = callback => {
  return API.graphql(graphqlOperation(onUpdatePipeline)).subscribe({
      next: callback,
      error: error => console.log(error)
  });
};

export const useOnDeletePipeline = callback => {
  return API.graphql(graphqlOperation(onDeletePipeline)).subscribe({
      next: callback,
      error: error => console.log(error)
  });
};